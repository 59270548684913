import { useCallback } from 'react'

import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Skeleton } from 'antd'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ViewColumnTemplateFragment } from 'types/graphql'

import Modal from 'src/components/Modal'
import Text from 'src/components/Typography/Text'
import {
  useDeleteViewCustomColumnTemplateMutation,
  useGetCustomColumnTemplatesForViewQuery,
} from 'src/fetch/views/viewColumnTemplates'
import { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

const StyledUL = styled.ul`
  margin: 0;
  > li {
    margin: 0;
    padding: 4px 0;
  }
`

type Props = {
  columnTemplate: ViewColumnTemplateFragment
  modalState: UseDisclosureReturn
  tableViewManager: TableViewManager
}
const DeleteCustomColumnModal = ({ columnTemplate, modalState, tableViewManager }: Props) => {
  const { data, isLoading } = useGetCustomColumnTemplatesForViewQuery({
    recordTypeId: tableViewManager.recordTypeId,
  })
  const latestVersion = data?.getViewCustomColumnTemplates.find(
    (ct) => ct.generatedId === columnTemplate.generatedId
  )
  const { mutateAsync: deleteColumn } = useDeleteViewCustomColumnTemplateMutation()
  const handleSubmit = useCallback(async () => {
    await deleteColumn({
      generatedId: columnTemplate.generatedId,
    })
    runInAction(() => {
      tableViewManager.columnTemplateStore.deleteColumnTemplate(columnTemplate)
    })
  }, [columnTemplate, deleteColumn, tableViewManager])
  return (
    <Modal
      {...modalState}
      title="Delete Custom Column"
      onOk={handleSubmit}
      okDisabled={isLoading}
      okText="Delete column"
      icon={faTrash}
      isDestructive
    >
      {isLoading && <Skeleton active />}
      {!isLoading && (
        <Flex vertical gap="12px">
          <Text>Are you sure you want to delete this column definition?</Text>
          {!!latestVersion?.usedInViews.length && (
            <>
              <Text>This column will be removed from all of the views where it is used:</Text>
              <StyledUL>
                {latestVersion.usedInViews.map((v) => (
                  <li key={v.name}>{v.name}</li>
                ))}
              </StyledUL>
            </>
          )}
        </Flex>
      )}
    </Modal>
  )
}

export default observer(DeleteCustomColumnModal)
