import { useState } from 'react'

import { Flex } from 'antd'
import { TableContext } from 'govwell-ui/components/Table/context'
import TableContent from 'govwell-ui/components/Table/TableContent'
import TableControls from 'govwell-ui/components/Table/TableControls'
import { Size } from 'govwell-ui/types'
import { v4 } from 'uuid'

type Props = {
  children?: React.ReactNode
  size?: Size
}
const TableBase = ({ children, size }: Props) => {
  const [uuid] = useState(v4())
  return (
    <Flex vertical gap="6px">
      <TableContext.Provider value={{ size, uuid }}>{children}</TableContext.Provider>
    </Flex>
  )
}

export default Object.assign(TableBase, {
  Content: TableContent,
  Controls: TableControls,
})
