import { useMemo } from 'react'

import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DropIndicator as AtlassianDropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box'
import styled from 'styled-components'
const Wrapper = styled.div<{
  $edge: Edge
  $bottom: React.CSSProperties['bottom']
  $left: React.CSSProperties['left']
  $top: React.CSSProperties['top']
  $width: React.CSSProperties['width']
}>`
  position: absolute;
  height: 0;
  > div {
    position: fixed;
    width: ${({ $width }) => $width};
    bottom: unset;
    left: ${({ $left }) => $left};
    right: unset;
    top: unset;
    ${({ $bottom, $edge, $top }) => {
      switch ($edge) {
        case 'bottom':
          return `top: ${$bottom};`
        case 'top':
          return `top: ${$top};`
      }
    }};
    transform: translateY(-2px);
  }
`
type Props = {
  edge: Extract<Edge, 'top' | 'bottom'> // right and left not yet implemented
  fixedLayout?: {
    dropTargetBoundingClientRect: DOMRect | undefined
    width: React.CSSProperties['width']
  }
}
const DropIndicator = ({ edge, fixedLayout }: Props) => {
  const children = useMemo(() => <AtlassianDropIndicator edge={edge} />, [edge])
  if (fixedLayout) {
    return (
      <Wrapper
        $edge={edge}
        $bottom={`${fixedLayout.dropTargetBoundingClientRect?.bottom ?? 0}px`}
        $left={`${fixedLayout.dropTargetBoundingClientRect?.left ?? 0}px`}
        $top={`${fixedLayout.dropTargetBoundingClientRect?.top ?? 0}px`}
        $width={fixedLayout.width}
      >
        {children}
      </Wrapper>
    )
  }
  return children
}

export default React.memo(DropIndicator)
