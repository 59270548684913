import { Flex } from 'antd'
import { Checkbox } from 'govwell-ui'
import isNil from 'lodash.isnil'
import { computed, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import EmptyState from 'src/components/EmptyState'
import DateFilter from 'src/components/TableViews/DateFilter'
import FilterTypeSelect from 'src/components/TableViews/FilterTypeSelect'
import OptionCheckbox from 'src/components/TableViews/OptionCheckbox'
import Text from 'src/components/Typography/Text'
import { FilterManager } from 'src/models/TableViews/FilterManager'
import { TableColumnTemplate } from 'src/models/TableViews/TableColumnTemplate'

import { useMyOrg } from '../../layouts/AppStateContextLayout/utils'
const StyledFlex = styled(Flex)`
  padding: 0px 12px;
`

type Props = {
  filterManager: FilterManager
  columnTemplate: TableColumnTemplate
}
const FilterEditor = ({ filterManager, columnTemplate }: Props) => {
  const filter = filterManager.getFilterByColumnGeneratedId(columnTemplate.generatedId)
  const paymentProviderFormatted = useMyOrg()?.paymentProviderFormatted || ''

  const renderInputs = () => {
    switch (columnTemplate.dataType) {
      case 'Boolean':
        return (
          <>
            <Checkbox
              value={filter.bool === true}
              onValueChange={(val) => {
                runInAction(() => {
                  if (!val) {
                    filter.bool = undefined
                  }
                  if (val) {
                    filter.bool = true
                  }
                })
              }}
            >
              True
            </Checkbox>
            <Checkbox
              value={filter.bool === false}
              onValueChange={(val) => {
                runInAction(() => {
                  if (!val) {
                    filter.bool = undefined
                  }
                  if (val) {
                    filter.bool = false
                  }
                })
              }}
            >
              False
            </Checkbox>
          </>
        )
      case 'Date':
      case 'DateTime':
        return <DateFilter filter={filter} />
      default: {
        if (columnTemplate.filterOptions.length === 0) {
          return (
            <EmptyState hideBorder size="sm">
              <EmptyState.Image />
              <EmptyState.Message>No options found</EmptyState.Message>
            </EmptyState>
          )
        }
        if (!isNil(filter.isNull)) {
          return null
        }
        const sortedOptions = computed(() =>
          columnTemplate.filterOptions
            .slice()
            .sort((a, b) =>
              filter
                .getOptionDisplayValue(a.identifier, paymentProviderFormatted)
                .localeCompare(filter.getOptionDisplayValue(b.identifier, paymentProviderFormatted))
            )
        ).get()
        return sortedOptions.map((o) => (
          <OptionCheckbox key={o.identifier} option={o} filter={filter} />
        ))
      }
    }
  }

  return (
    <StyledFlex vertical gap="6px">
      <Flex gap="6px">
        <Text colorToken="colorTextSecondary">{columnTemplate.labelSentenceCase}</Text>
        <FilterTypeSelect filterManager={filterManager} columnTemplate={columnTemplate} />
      </Flex>
      {renderInputs()}
    </StyledFlex>
  )
}

export default observer(FilterEditor)
