import { ReactNode, forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from 'antd'
import { Size } from 'govwell-ui/types'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { FormSelectLoadingPlaceholder } from 'src/components/form/shared/LoadingPlaceholder'
import LottieAnimation from 'src/components/Lottie'
import Text, { TextSize } from 'src/components/Typography/Text'
import { useMergeRefs } from 'src/hooks/use-merge-refs'
import { LottiesLoading } from 'src/lotties/loading'

const StyledFlex = styled(Flex)<{
  $height: React.CSSProperties['height']
  $isDisabled: boolean
  $padding: React.CSSProperties['padding']
  $size: Size
  $width: React.CSSProperties['width']
}>`
  border: solid 1px ${({ theme }) => theme.colorBorder};
  border-radius: 6px;
  padding: ${({ $padding, $size }) => {
    if ($padding) {
      return $padding
    }
    switch ($size) {
      case 'sm':
        return '0px 12px'
      default:
        return '3px 12px'
    }
  }};
  height: ${({ $height }) => ($height ? $height : 'auto')};
  width: ${({ $width }) => ($width ? $width : 'auto')};

  ${({ $size }) => {
    switch ($size) {
      case 'sm':
        return `
          min-height: 24px;
          font-size: 10px;
        `
      case 'md':
        return `
          min-height: 32px;
          font-size: 14px;
        `
      case 'lg':
        return `
          min-height: 40px;
          font-size: 18px;
        `
    }
  }}

  ${({ $isDisabled, theme }) =>
    $isDisabled
      ? `
      pointer-events: none;
      background-color: ${theme.controlItemBgActiveDisabled};
  `
      : `
      background-color: ${theme.colorWhite};
      &:hover: {
        border-color: ${theme.colorPrimaryHover};
      }
  `}

  &:focus-within {
    border-color: ${({ theme }) => theme.colorPrimaryBase};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.controlOutline};
  }
`
const StyledInput = styled.input`
  border: none;
  color: ${({ theme }) => theme.colorText};
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamily};
  flex-grow: 1;
  flex-basis: 0;
  min-width: 40px;

  :read-only {
    cursor: default;
  }

  ::placeholder {
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.colorTextPlaceholder};
  }
`

const StyledXButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0px 4px;
  border-radius: 4px;
  line-height: 0px;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colorPrimaryBorder};
  }
`

export type InputBaseProps = {
  ariaActiveDescendant?: React.HTMLProps<HTMLInputElement>['aria-activedescendant']
  ariaControls?: React.HTMLProps<HTMLInputElement>['aria-controls']
  ariaDescribedBy?: React.HTMLProps<HTMLInputElement>['aria-describedby']
  ariaExpanded?: React.HTMLProps<HTMLInputElement>['aria-expanded']
  autoFocus?: boolean
  id?: string
  isClearable?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isReadOnly?: boolean
  isRequired?: boolean
  onClear?: () => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onValueChange: (value: string) => void
  padding?: React.CSSProperties['padding']
  placeholder?: string
  prefixIcon?: IconDefinition
  prefix?: ReactNode
  role?: React.HTMLProps<HTMLInputElement>['role']
  size?: Size
  suffixIcon?: IconDefinition
  suffix?: ReactNode
  value: string
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
}
const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (
    {
      ariaActiveDescendant,
      ariaControls,
      ariaDescribedBy,
      ariaExpanded,
      autoFocus,
      id: propId,
      isClearable,
      isDisabled = false,
      isLoading,
      isReadOnly,
      isRequired,
      onBlur,
      onClear,
      onFocus,
      onKeyDown,
      onValueChange,
      padding,
      placeholder,
      prefix = null,
      prefixIcon,
      role,
      size = 'md',
      suffix = null,
      suffixIcon,
      value,
      width,
      height,
    }: InputBaseProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const mergedRef = useMergeRefs(ref, inputRef)
    const hasValue = !!value
    const isShowingClearButton = hasValue && isClearable
    const isShowingFullLoadingBar = isLoading && !value?.length
    const [id] = useState(propId ?? uuid())

    const [hasAutoFocused, setHasAutoFocused] = useState(false)
    useEffect(() => {
      if (autoFocus && !isLoading && !hasAutoFocused) {
        inputRef.current?.select()
        setHasAutoFocused(true)
      }
    }, [autoFocus, hasAutoFocused, isLoading])

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(e.target.value)
      },
      [onValueChange]
    )

    const handleClick = useCallback(() => {
      inputRef.current?.focus()
    }, [])

    const handleClear = useCallback(() => {
      onClear?.()
      onValueChange('')
    }, [onClear, onValueChange])

    return (
      <StyledFlex
        align="center"
        gap="6px"
        $size={size}
        onClick={handleClick}
        $isDisabled={isDisabled}
        $padding={padding}
        $width={width}
        $height={height}
      >
        {prefixIcon && (
          <Text colorToken="colorIcon">
            <FontAwesomeIcon icon={prefixIcon} />
          </Text>
        )}
        <Flex wrap="wrap" gap="3px" style={{ flexGrow: 1, flexBasis: 0, minWidth: 0 }}>
          {prefix}
          {isShowingFullLoadingBar && <FormSelectLoadingPlaceholder />}
          {!isShowingFullLoadingBar && (
            <StyledInput
              aria-activedescendant={ariaActiveDescendant}
              aria-controls={ariaControls}
              aria-describedby={ariaDescribedBy}
              aria-expanded={ariaExpanded}
              autoComplete="off"
              autoFocus={autoFocus}
              disabled={isDisabled}
              id={id}
              onBlur={onBlur}
              onChange={handleChange}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              readOnly={isReadOnly}
              ref={mergedRef}
              required={isRequired}
              role={role}
              type="text"
              value={value}
            />
          )}
          {suffix}
        </Flex>
        {isShowingClearButton && (
          <StyledXButton onClick={handleClear} title="Clear input">
            <Text size={TextSize.Small} colorToken="colorIcon">
              <FontAwesomeIcon icon={faX} />
            </Text>
          </StyledXButton>
        )}
        {isLoading && !isShowingFullLoadingBar && (
          // Show this discreet spinner when there's already some search query text you don't want to obfuscate
          <LottieAnimation animationData={LottiesLoading} width={22} height={22} />
        )}
        {suffixIcon && (
          <Text colorToken="colorIcon">
            <FontAwesomeIcon icon={suffixIcon} />
          </Text>
        )}
      </StyledFlex>
    )
  }
)

export default InputBase
