import { ReactNode, useState } from 'react'

import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Flex } from 'antd'
import { Size } from 'govwell-ui/types'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import Text, { TextSize } from 'src/components/Typography/Text'

const StyledCheckbox = styled(CheckboxPrimitive.Root)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  padding: 0px;
  border: solid 1px ${({ theme }) => theme.colorBorder};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  transition: border-color 0.25s;

  &:hover {
    border-color: ${({ theme }) => theme.colorPrimaryBase};
  }

  &:focus {
    outline-offset: 1px;
    outline: solid 3px ${({ theme }) => theme.controlItemBgaAtiveHover};
  }

  &[data-state='checked'] {
    border-color: ${({ theme }) => theme.colorPrimaryBase};
    background-color: ${({ theme }) => theme.colorPrimaryBase};
    color: ${({ theme }) => theme.white};
    svg {
      color: ${({ theme }) => theme.white};
    }
  }
`
const StyledLabel = styled.label`
  cursor: pointer;
`

type CheckboxBaseProps = {
  value?: true | false | 'indeterminate'
  onValueChange?: (value: true | false | 'indeterminate') => void
  children?: ReactNode
  size?: Size
  tabIndex?: number
}
const Checkbox = ({
  value = false,
  onValueChange,
  children,
  size = 'md',
  tabIndex,
}: CheckboxBaseProps) => {
  const [id] = useState(uuid())
  return (
    <Flex align="center" gap="8px">
      <StyledCheckbox
        checked={value}
        onCheckedChange={onValueChange}
        id={id}
        tabIndex={tabIndex}
        {...(tabIndex === -1
          ? {
              role: 'presentation',
              ['aria-checked']: false,
            }
          : {})}
      >
        <CheckboxPrimitive.Indicator>
          <FontAwesomeIcon icon={faCheck} />
        </CheckboxPrimitive.Indicator>
      </StyledCheckbox>
      {children ? (
        <StyledLabel htmlFor={id}>
          <Text size={size === 'md' ? TextSize.Base : TextSize.Small}>{children}</Text>
        </StyledLabel>
      ) : null}
    </Flex>
  )
}

export default Checkbox
