import { makeAutoObservable } from 'mobx'
import { ViewColumnFragment } from 'types/graphql'

import { TableViewColumn } from 'src/models/TableViews/TableViewColumn'
import { TableViewColumnTemplateStore } from 'src/models/TableViews/TableViewColumnTemplateStore'
import { filterNil } from 'src/utils'

export class ColumnManager {
  private _columns: TableViewColumn[] = []

  private _columnTemplateStore: TableViewColumnTemplateStore
  constructor(args: {
    columns: ViewColumnFragment[]
    columnTemplateStore: TableViewColumnTemplateStore
  }) {
    this._columnTemplateStore = args.columnTemplateStore
    const columns: TableViewColumn[] = []
    args.columns.forEach((c, displayIndex) => {
      columns.push(
        new TableViewColumn({
          columnGeneratedId: c.columnTemplateGeneratedId,
          columnTemplateStore: args.columnTemplateStore,
          displayIndex,
        })
      )
    })
    this._columns = columns
    makeAutoObservable(this)
  }

  public get columns(): TableViewColumn[] {
    // Columns with no column template should only include custom columns that were deleted in this session
    return this._columns.filter((c) => !!c.columnTemplate)
  }

  public set columns(value: TableViewColumn[]) {
    this._columns = value
  }

  public get columnsByColumnGeneratedId(): Map<string, TableViewColumn> {
    return new Map(
      filterNil(
        this.columns.map((c) => (c.columnTemplate ? [c.columnTemplate.generatedId, c] : null))
      )
    )
  }

  public toggleColumnTypeVisibility(columnGeneratedId: string) {
    const index = this.columns.findIndex((c) => c.columnTemplate?.generatedId === columnGeneratedId)
    if (index < 0) {
      const columnTemplate = this._columnTemplateStore.columnTemplates.find(
        (ct) => ct.generatedId === columnGeneratedId
      )
      if (!columnTemplate) {
        return // Should not happen
      }
      this._columns.push(
        new TableViewColumn({
          columnGeneratedId,
          columnTemplateStore: this._columnTemplateStore,
          displayIndex: this.columns.length,
        })
      )
    }
    // don't allow hiding last column
    else if (this.columns.length > 1) {
      this._columns.splice(index, 1)
    }
  }
}
