import { useMemo } from 'react'

import { SelectOption } from 'govwell-ui/components/Select/types'
import {
  ContactFragment,
  CreateRecipientInput,
  FieldFragment,
  FieldGroupFragment,
  GetSendRecordEmailModalQuery,
  RecordAccessFragment,
  RecipientContactFieldFragment,
  UserDataFragment,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'

import {
  RecordUserSelectApplicantValue,
  RecordUserSelectOwnerValue,
} from 'src/components/RecordTemplateConfigurationCell/WorkflowTemplatesTab/util'
import {
  useMyOrgActiveUsers,
  useMyOrgOtherActiveUsers,
} from 'src/layouts/AppStateContextLayout/utils'
import { filterNil, formatUserName } from 'src/utils'
import { getOptionFromUser } from 'src/utils/users'

type Presets = typeof RecordUserSelectApplicantValue | typeof RecordUserSelectOwnerValue
export type RecordUserSelectOptionValue =
  | ContactFragment
  | Presets
  | RecordAccessFragment
  | RecipientContactFieldFragment
  | UserDataFragment
export const PresetRecordUserSelectApplicantOption: SelectOption<RecordUserSelectOptionValue> = {
  group: 'Presets',
  label: 'Applicant',
  value: RecordUserSelectApplicantValue,
}
export const PresetRecordUserSelectOwnerOption: SelectOption<RecordUserSelectOptionValue> = {
  group: 'Presets',
  label: 'Record Owner',
  value: RecordUserSelectOwnerValue,
}
const useRecordUserPresetSelectOptions = (
  allowApplicant: boolean
): SelectOption<RecordUserSelectOptionValue>[] =>
  useMemo(() => {
    if (allowApplicant) {
      return [PresetRecordUserSelectApplicantOption, PresetRecordUserSelectOwnerOption]
    }
    return [PresetRecordUserSelectOwnerOption]
  }, [allowApplicant])

export const useRecordApplicationFields = (args: {
  formFieldGroups: FieldGroupFragment[]
  formFileFieldGroup: FieldGroupFragment | null | undefined
  filesFieldGroup: FieldGroupFragment | null | undefined
}): FieldFragment[] => {
  const { formFieldGroups, formFileFieldGroup, filesFieldGroup } = args

  return useMemo(() => {
    const fieldGroups: FieldGroupFragment[] = [
      ...(formFieldGroups || []),
      ...(filesFieldGroup ? [filesFieldGroup] : []),
      ...(formFileFieldGroup ? [formFileFieldGroup] : []),
    ].filter((fg) => !!fg)
    const fields: FieldFragment[] = fieldGroups
      .flatMap((fg) => fg.fields || [])
      .flatMap((val) => (val === null ? [] : [val]))
    return fields
  }, [filesFieldGroup, formFieldGroups, formFileFieldGroup])
}

export const useContactFieldsFromFormFieldGroups = <
  TContactField extends RecipientContactFieldFragment,
>(
  fields: (TContactField | null | undefined)[] | null | undefined
) => {
  const contactFields = useMemo(
    () => filterNil(fields ?? []).filter((f) => f.type === 'Contact'),
    [fields]
  )
  const contactRecordFields = useMemo(
    () =>
      filterNil(fields ?? []).filter(
        (f) => f.type === 'Record' && f.record?.recordTemplate?.recordType?.isContact
      ),
    [fields]
  )
  return {
    contactFields,
    contactRecordFields,
  }
}

export const useSendRecordEmailRecipientOptions = (
  data: GetSendRecordEmailModalQuery | undefined
) => {
  const { accesses, applicantUser, contactsFieldGroup, formFieldGroups } = data?.recordById ?? {}

  const allowApplicant = !!applicantUser
  const otherOrgUsers = useMyOrgOtherActiveUsers()
  const presets = useRecordUserPresetSelectOptions(allowApplicant)
  const fields = useMemo(() => formFieldGroups?.flatMap((fg) => fg.fields), [formFieldGroups])
  const { contactFields, contactRecordFields } = useContactFieldsFromFormFieldGroups(fields)

  const userOptions: SelectOption<RecordUserSelectOptionValue>[] = useMemo(() => {
    const userOptionsById = new Map<number, SelectOption<RecordUserSelectOptionValue>>()
    const contactOptions = [
      ...filterNil(contactsFieldGroup?.fields?.flatMap((f) => f.contact) ?? [])
        .filter((c) => c.email)
        .map((c) => ({
          group: 'Contacts',
          label: formatUserName(c),
          value: c,
        })),
      ...contactFields
        .filter((f) => f.basedOnFieldId && f.contact?.email)
        .map((f) => ({
          group: 'Contacts',
          label: formatUserName(f.contact),
          value: f,
        })),
      ...contactRecordFields
        .filter((f) => f.basedOnFieldId && f.record?.contactField?.contact?.email)
        .map((f) => ({
          group: 'Contacts',
          label: formatUserName(f.record?.contactField?.contact),
          value: f,
        })),
    ]
    const users = [
      ...otherOrgUsers.map((u) => ({
        ...getOptionFromUser(u),
        group: 'Other Staff',
      })),
    ]
    users.forEach((u) => {
      userOptionsById.set(u.value.id, u)
    })
    const recordAccessOptions = [
      ...(accesses?.length
        ? accesses
            .filter((a) => !userOptionsById.has(a.user.id))
            .map((a) => ({
              group: 'Shared With',
              label: formatUserName(a.user),
              value: a,
            }))
        : []),
    ]
    return [
      ...presets,
      ...Array.from(userOptionsById.values()),
      ...recordAccessOptions,
      ...contactOptions,
    ]
  }, [
    accesses,
    contactFields,
    contactRecordFields,
    contactsFieldGroup?.fields,
    otherOrgUsers,
    presets,
  ])

  return userOptions
}

export const getCreateRecipientInputsFromRecordUserSelectOptions = (
  selectedUserOptions: SelectOption<RecordUserSelectOptionValue>[]
): CreateRecipientInput[] => {
  const contactIds: number[] = []
  const basedOnFieldIds: number[] = []
  const recipientUserIds: number[] = []
  const recordAccessIds: number[] = []
  selectedUserOptions.forEach((o) => {
    if (typeof o.value !== 'string') {
      switch (o.value.__typename) {
        case 'Contact':
          contactIds.push(o.value.id)
          break
        case 'Field':
          if (o.value.basedOnFieldId) {
            basedOnFieldIds.push(o.value.basedOnFieldId)
          }
          break
        case 'RecordAccess':
          recordAccessIds.push(o.value.id)
          break
        case 'User':
          recipientUserIds.push(o.value.id)
          break
      }
    }
  })

  return [
    ...basedOnFieldIds.map((basedOnFieldId) => ({ basedOnFieldId })),
    ...contactIds.map((contactId) => ({ contactId })),
    ...recipientUserIds.map((userId) => ({ userId })),
    ...recordAccessIds.map((recordAccessId) => ({ recordAccessId })),
  ]
}

export const useRecordTaskUserOptions = (args: {
  allowApplicant: boolean
}): SelectOption<RecordUserSelectOptionValue>[] => {
  const { allowApplicant } = args
  const activeUsers = useMyOrgActiveUsers()
  const presets = useRecordUserPresetSelectOptions(allowApplicant)
  const options: SelectOption<RecordUserSelectOptionValue>[] = useMemo(
    () => [
      ...presets,
      ...activeUsers.map((u) => ({
        group: 'Users',
        ...getOptionFromUser(u),
      })),
    ],
    [activeUsers, presets]
  )
  return options
}

export const getUrlForRecord = (args: {
  isDraft: boolean
  organizationSlug: string | null | undefined
  recordUuid: string
}) => {
  const { isDraft, organizationSlug, recordUuid } = args
  if (isDraft) {
    return routes.editRecordDraft({
      recordUuid,
      slug: organizationSlug || '',
    })
  }
  return routes.record({
    recordUuid,
  })
}
