import { useEffect, useMemo, useState } from 'react'

import { ExpandedState, PaginationState, RowSelectionState } from '@tanstack/react-table'

import useDebounce from 'src/hooks/use-debounce'

export const DEFAULT_PAGE_SIZE = 50

export type SortingState<TSortKey> = {
  id: TSortKey
  desc: boolean
}[]

export type TableState<TData, TSortKey extends string = string> = {
  debouncedSearchQuery: string
  expanded: ExpandedState
  onExpandedChange: (expanded: ExpandedState) => void
  onPaginationChange: (pagination: PaginationState) => void
  onRowSelectionChange: (rowSelection: RowSelectionState) => void
  onSearchQueryChange: (searchQuery: string) => void
  onSortingChange: (sorting: SortingState<TSortKey>) => void
  pagination: PaginationState
  rowSelection: RowSelectionState
  searchQuery: string
  selectedRowSet: Set<TData>
  sorting: SortingState<TSortKey>
}

export const useTableState = <TData, TSortKey extends string = string>(args?: {
  autoResetKey?: string
  initialState?: {
    pagination?: PaginationState
    sorting?: SortingState<TSortKey>
  }
}): TableState<TData, TSortKey> => {
  const { autoResetKey, initialState } = args ?? {}
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery)
  const defaultPaginationState = useMemo(
    () => ({
      pageIndex: initialState?.pagination?.pageIndex ?? 0,
      pageSize: initialState?.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    }),
    [initialState?.pagination?.pageIndex, initialState?.pagination?.pageSize]
  )
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationState)
  const defaultSortingState = useMemo(() => initialState?.sorting ?? [], [initialState?.sorting])
  const [sorting, setSorting] = useState<SortingState<TSortKey>>(defaultSortingState)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [selectedRowSet, setSelectedRowSet] = useState<Set<TData>>(new Set<TData>())
  const [expanded, setExpanded] = useState<ExpandedState>({})

  useEffect(() => {
    setExpanded({})
    setPagination(defaultPaginationState)
    setRowSelection({})
    setSelectedRowSet(new Set<TData>())
    setSorting(defaultSortingState)
  }, [autoResetKey, defaultPaginationState, defaultSortingState, searchQuery])

  return {
    debouncedSearchQuery,
    expanded,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSearchQueryChange: setSearchQuery,
    onSortingChange: setSorting,
    pagination,
    rowSelection,
    searchQuery,
    selectedRowSet,
    sorting,
  }
}
