import {
  CreateViewCustomColumnTemplateMutation,
  CreateViewCustomColumnTemplateMutationVariables,
  DeleteViewCustomColumnTemplateMutation,
  DeleteViewCustomColumnTemplateMutationVariables,
  GetCustomColumnTemplatesForViewQuery,
  GetCustomColumnTemplatesForViewQueryVariables,
  GetViewCustomColumnTemplatesInput,
  UpdateViewCustomColumnTemplateMutation,
  UpdateViewCustomColumnTemplateMutationVariables,
} from 'types/graphql'

import { QueryKey, useMutation, useQuery } from 'src/utils/queryClient'

const Create = gql`
  mutation CreateViewCustomColumnTemplateMutation($input: CreateViewCustomColumnTemplateInput!) {
    createViewCustomColumnTemplate(input: $input) {
      ...ViewColumnTemplateFragment
    }
  }
`
export const useCreateViewCustomColumnTemplateMutation = () => {
  return useMutation<
    CreateViewCustomColumnTemplateMutation,
    CreateViewCustomColumnTemplateMutationVariables
  >({
    mutationDocument: Create,
  })
}

const Delete = gql`
  mutation DeleteViewCustomColumnTemplateMutation($generatedId: String!) {
    deleteViewCustomColumnTemplate(generatedId: $generatedId)
  }
`
export const useDeleteViewCustomColumnTemplateMutation = () => {
  return useMutation<
    DeleteViewCustomColumnTemplateMutation,
    DeleteViewCustomColumnTemplateMutationVariables
  >({
    mutationDocument: Delete,
  })
}

const Get = gql`
  query GetCustomColumnTemplatesForViewQuery($input: GetViewCustomColumnTemplatesInput!) {
    getViewCustomColumnTemplates(input: $input) {
      ...ViewColumnTemplateFragment
    }
  }
`
export const useGetCustomColumnTemplatesForViewQuery = (
  args: Partial<GetViewCustomColumnTemplatesInput>
) => {
  return useQuery<
    GetCustomColumnTemplatesForViewQuery,
    GetCustomColumnTemplatesForViewQueryVariables
  >({
    queryKey: [QueryKey.ViewCustomColumnTemplates, args],
    queryDocument: Get,
    variables: {
      input: {
        recordTypeId: args.recordTypeId ?? -1,
      },
    },
    enabled: !!args.recordTypeId,
    gcTime: 0,
    staleTime: 0,
  })
}

const Update = gql`
  mutation UpdateViewCustomColumnTemplateMutation($input: UpdateViewCustomColumnTemplateInput!) {
    updateViewCustomColumnTemplate(input: $input) {
      ...ViewColumnTemplateFragment
    }
  }
`
export const useUpdateViewCustomColumnTemplateMutation = () => {
  return useMutation<
    UpdateViewCustomColumnTemplateMutation,
    UpdateViewCustomColumnTemplateMutationVariables
  >({
    mutationDocument: Update,
  })
}
