import { routes } from '@redwoodjs/router'

import {
  useIsGov,
  useIsGovWellStaff,
  useMyRecordTypes,
  useMyUser,
} from '../layouts/AppStateContextLayout/utils'

export const useHomePage = () => {
  const isGov = useIsGov()
  const isGovWellStaff = useIsGovWellStaff()
  const recordTypes = useMyRecordTypes()
  const me = useMyUser()

  if (isGovWellStaff) {
    return routes.adminOrgs()
  }
  if (isGov) {
    if (recordTypes?.length) {
      if (me?.group === 'Reviewer') {
        return routes.tasks()
      }
      const recordTypeId = recordTypes?.[0]?.id
      if (recordTypeId) {
        return routes.recordsList({ recordTypeId })
      }
    }
    return routes.settings()
  } else {
    return routes.citizenRecords()
  }
}
