import React, { useEffect, useState } from 'react'

import { Tooltip } from 'antd'

type Props = {
  isEnabled: boolean
  originalTextChildren: React.ReactNode
  childrenRef: React.RefObject<HTMLElement>
  children: React.ReactNode
}
const AutomaticTruncationTooltip = ({
  isEnabled,
  originalTextChildren,
  childrenRef,
  children,
}: Props) => {
  const [title, setTitle] = useState<React.ReactNode>('')
  useEffect(() => {
    const el = childrenRef.current
    if (!el || !isEnabled) {
      return
    }

    const listener = (e: MouseEvent) => {
      const target = e.currentTarget as HTMLElement
      if (target.scrollWidth > target.clientWidth || target.scrollHeight > target.clientHeight) {
        setTitle(originalTextChildren)
      } else {
        setTitle('')
      }
    }
    el.addEventListener('mouseenter', listener)
    return () => el.removeEventListener('mouseenter', listener)
  }, [childrenRef, isEnabled, originalTextChildren])

  if (isEnabled) {
    return <Tooltip title={title}>{children}</Tooltip>
  }

  return <React.Fragment>{children}</React.Fragment>
}

export default React.memo(AutomaticTruncationTooltip)
