import { useCallback } from 'react'

import {
  IconDefinition,
  faBuilding,
  faEnvelope,
  faFax,
  faIdCard,
  faLocationPin,
  faPhone,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Tag } from 'antd'
import isNil from 'lodash.isnil'
import styled from 'styled-components'

import { ForeignValueDisplay } from 'src/components/ForeignValues/ForeignValueDisplay'
import { RecordAutocompleteFieldValues } from 'src/components/form/shared/RecordAutocompleteCell/RecordAutocomplete'
import Text from 'src/components/Typography/Text'

import { FieldType, FileFragment, ForeignValue, State } from '../../../types/graphql'
import { formatPhoneNumber } from '../../utils'
import { DateFormats, formatDate } from '../../utils/date'
import RecordIdLinkCell from '../RecordIdLinkCell'
import { RenderedSignature } from '../RenderedSignature'
import { TableLinkNewPage } from '../shared/StyledComponents'

import { formatFieldContactAddress } from './util'

interface FieldDisplayInput {
  type: FieldType
  checked?: boolean | null
  num?: number | null
  str?: string | null
  strs?: (string | null)[] | null
  record?: RecordAutocompleteFieldValues['selectedOption'] | null
  inspectionResult?: {
    id: number
    isPassing: boolean
    name: string
  } | null
  contact?: {
    id?: number
    firstName?: string | null
    lastName?: string | null
    middleName?: string | null
    email?: string | null
    companyName?: string | null
    phoneNumber?: string | null
    faxNumber?: string | null
    title?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: State | null
    zip?: string | null
  } | null
  file?: FileFragment | null
  files?: (FileFragment | null)[] | null
  violationTypes?: {
    id?: number
    name?: string
  }[]
  recordCollections?:
    | ({
        id?: number
        name?: string | null
      } | null)[]
    | null
  foreignValue?: ForeignValue | null
  userHasAccessToForeignValue?: boolean | null
}

type Props = {
  input: FieldDisplayInput
  showDashAsFallback?: boolean
}

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

// the backend has related Field display code for documents in stringifyFormFieldInput
export const FieldDisplay = (props: Props) => {
  const {
    input: {
      type,
      checked,
      num,
      str,
      strs,
      record,
      inspectionResult,
      contact,
      file,
      files,
      recordCollections,
      violationTypes,
      foreignValue,
      userHasAccessToForeignValue,
    },
    showDashAsFallback = true,
  } = props
  const Fallback = useCallback(() => (showDashAsFallback ? '-' : null), [showDashAsFallback])
  switch (type) {
    case 'ShortText': {
      const strings = [...(strs?.length ? strs : [str])].filter((s) => !!s?.trim())
      if (!strings.length) {
        return <Fallback />
      }
      return (
        <Flex vertical gap="4px">
          {strings.map((string, index) => (
            <div key={index}>{string}</div>
          ))}
        </Flex>
      )
    }
    case 'LongText':
    case 'Address':
    case 'Radio': {
      return str ? <Text>{str}</Text> : <Fallback />
    }
    case 'Date': {
      return str ? formatDate(str, DateFormats.MonthNameDateNoTime) : <Fallback />
    }
    case 'Dropdown': {
      return strs ? <Text>{strs.join(', ')}</Text> : <Fallback />
    }
    case 'Currency': {
      return !isNil(num) && !isNaN(num) ? `$${num}` : <Fallback />
    }
    case 'Number': {
      return !isNil(num) && !isNaN(num) ? `${num}` : <Fallback />
    }
    case 'Signature': {
      const url = file?.url?.signedUrl
      if (!url) {
        return <Fallback />
      }
      return <RenderedSignature url={file?.url?.signedUrl ?? ''} type="modal" />
    }
    case 'InspectionResult': {
      if (!inspectionResult) {
        return <Fallback />
      }
      return <Tag color={inspectionResult.isPassing ? 'green' : 'red'}>{inspectionResult.name}</Tag>
    }
    case 'Checkbox': {
      return checked ? 'Yes' : 'No'
    }
    case 'Record': {
      return record?.id ? <RecordIdLinkCell id={record.id} /> : <Fallback />
    }
    case 'Contact': {
      if (!contact || Object.keys(contact).every((k) => !contact[k as keyof typeof contact])) {
        return <Fallback />
      }
      const { firstName, lastName, email, phoneNumber, companyName, faxNumber, title } = contact
      const makeIcon = (icon: IconDefinition) => (
        <FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} />
      )

      const renderLine = (icon: IconDefinition, val?: string) =>
        val ? (
          <div>
            {makeIcon(icon)}
            {val}
          </div>
        ) : (
          ''
        )

      const name = [firstName, lastName]
        .filter((a) => !!a)
        .map((a) => a?.trim())
        .join(' ')

      const formattedAddress = formatFieldContactAddress(contact)
      return (
        <>
          {renderLine(faUser, name)}
          {renderLine(faBuilding, companyName ?? '')}
          {renderLine(faIdCard, title ?? '')}
          {phoneNumber && renderLine(faPhone, formatPhoneNumber(phoneNumber))}
          {faxNumber && renderLine(faFax, formatPhoneNumber(faxNumber))}
          {email && renderLine(faEnvelope, email)}
          {formattedAddress && renderLine(faLocationPin, formattedAddress)}
        </>
      )
    }
    case 'File': {
      if (!file) {
        return <Fallback />
      }
      return <TableLinkNewPage href={file?.url?.signedUrl ?? ''}>{file?.name}</TableLinkNewPage>
    }
    case 'Files': {
      if (!files?.length) {
        return <Fallback />
      }
      return (
        <Vertical>
          {files.map((f) => (
            <TableLinkNewPage key={`file-${f?.id}`} href={f?.url?.signedUrl ?? ''}>
              {f?.name}
            </TableLinkNewPage>
          ))}
        </Vertical>
      )
    }
    case 'ViolationType': {
      if (!violationTypes?.length) {
        return <Fallback />
      }
      return (
        <Vertical>
          {violationTypes.map((vt) => (
            <div key={`vt-${vt.id}`}>{vt.name}</div>
          ))}
        </Vertical>
      )
    }
    case 'RecordCollection': {
      if (!recordCollections?.length) {
        return <Fallback />
      }
      return (
        <Vertical>
          {recordCollections.map((rc) => (
            <div key={`rc-${rc?.id}`}>{rc?.name}</div>
          ))}
        </Vertical>
      )
    }
    case 'ForeignValue': {
      return foreignValue ? (
        <ForeignValueDisplay
          id={foreignValue.id}
          type={foreignValue.type}
          userHasAccessToForeignValue={!!userHasAccessToForeignValue}
        />
      ) : (
        <Fallback />
      )
    }
  }
}
