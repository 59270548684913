import { ButtonProps } from 'antd'
import { CSSProperties } from 'styled-components'

import { PaymentButtonRecordTaskPayment } from '../../../../../types/graphql'
import { RefetchFnType } from '../../../../types'

export interface PaymentButtonProps {
  type: ButtonProps['type']
  text: string
  recordTaskPayment: PaymentButtonRecordTaskPayment
  refetch: RefetchFnType
  size?: ButtonProps['size']
  recordUuid: string
  recordIdentifier: string
  recordIssuedIdentifier: string
  recordTemplateName: string
  orgSlug: string
  isACHEnabled: boolean
  paymentFeeBps: number
  style?: CSSProperties
  formattedRecordType: string
  tooltip?: string
}

export enum FinixFormType {
  ACH = 'ACH',
  CreditDebit = 'CreditDebit',
}
