import { useCallback } from 'react'

import { faArrowsTurnRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'

import TextArea from 'src/components/form/TextArea'

import { UserDataFragment } from '../../../../types/graphql'
import { useReassignMultipleRecordTaskInspectionAttemptsMutation } from '../../../fetch/recordTaskInspectionAttempts'
import useDisclosure, { UseDisclosureReturn } from '../../../hooks/use-disclosure'
import { RefetchFnType } from '../../../types'
import { OwnerSelect } from '../../form/shared/OwnerSelect'
import Modal from '../../Modal'

type Props = {
  recordTaskInspectionAttemptIds: number[]
  refetch: RefetchFnType
  modalState: UseDisclosureReturn
}

interface FormValues {
  inspectorUserId: string
  schedulingNotes?: string
}

export const ReassignMultipleInspectionsModal = (props: Props) => {
  const { refetch, modalState, recordTaskInspectionAttemptIds } = props

  const { mutateAsync: reassignFn } = useReassignMultipleRecordTaskInspectionAttemptsMutation()
  const onOk = useCallback(
    async (formValues: FormValues) => {
      const { inspectorUserId, schedulingNotes } = formValues
      const inspectorId = parseInt(inspectorUserId)
      await reassignFn({
        ids: recordTaskInspectionAttemptIds,
        input: {
          inspectorUserId: inspectorId,
          schedulingNotes: schedulingNotes?.trim() || undefined,
        },
      })
      await refetch()
    },
    [reassignFn, recordTaskInspectionAttemptIds, refetch]
  )

  return (
    <Modal
      {...modalState}
      form
      title={`Reassign Inspections (${recordTaskInspectionAttemptIds.length})`}
      okText={`Reassign inspections (${recordTaskInspectionAttemptIds.length})`}
      icon={faArrowsTurnRight}
      onOk={onOk}
    >
      <OwnerSelect
        label="Inspector"
        fieldName="inspectorUserId"
        caption={`The inspector you'd like to
        reassign these inspections to.`}
        placeholder="Select an inspector"
        required
        errorMessage="You must select an inspector"
        filterUser={(u: UserDataFragment) => !!u.isInspector}
      />
      <TextArea
        fieldName="schedulingNotes"
        label="Notes"
        placeholder="e.g. The inspector will try to be there between 2pm and 4pm."
        caption="Optionally update all selected inspections with these notes"
      />
    </Modal>
  )
}

type ButtonProps = {
  recordTaskInspectionAttemptIds: number[]
  refetch: RefetchFnType
}
export const ReassignMultipleInspectionsButton = ({
  recordTaskInspectionAttemptIds,
  refetch,
}: ButtonProps) => {
  const modalState = useDisclosure()
  return (
    <>
      <Button
        type="default"
        icon={<FontAwesomeIcon icon={faArrowsTurnRight} />}
        onClick={modalState.open}
      >
        Reassign
      </Button>
      <ReassignMultipleInspectionsModal
        modalState={modalState}
        recordTaskInspectionAttemptIds={recordTaskInspectionAttemptIds}
        refetch={refetch}
      />
    </>
  )
}
