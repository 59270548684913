import { Tag, TagProps } from 'antd'
import dayjs from 'dayjs'
import styled from 'styled-components'
import {
  OrganizationInspectionCoordinationType,
  RecordTaskInspectionAttemptFragment,
} from 'types/graphql'

import Text from 'src/components/Typography/Text'

import { DateFormats, formatDate } from '../../../../../utils/date'

const InlineTag = styled(Tag)`
  height: fit-content;
  width: fit-content;
  max-width: 100%;
  white-space: normal;
  word-break: break-word;
`

type Props = {
  attempt: RecordTaskInspectionAttemptFragment
  isAvailable: boolean
  showDate?: boolean
  inspectionCoordinationType: OrganizationInspectionCoordinationType | null | undefined
}
const TableCellInspectionStatus = (props: Props) => {
  const { label, tagColor } = getInspectionStatusDisplayConfig(props)
  return (
    <InlineTag color={tagColor}>
      <Text color="inherit" maxLines={2}>
        {label || '-'}
      </Text>
    </InlineTag>
  )
}

export default React.memo(TableCellInspectionStatus)

export const getInspectionStatusDisplayConfig = (
  props: Props
): {
  label: string | undefined
  tagColor: TagProps['color']
} => {
  const { attempt, isAvailable, showDate, inspectionCoordinationType = 'SchedulingRequest' } = props

  const scheduledStr = attempt.scheduledFor
    ? formatDate(attempt.scheduledFor, DateFormats.MonthDayYearWithSlash)
    : ''

  const requestedStr = attempt.requestedFor
    ? formatDate(attempt.requestedFor, DateFormats.MonthDayYearWithSlash)
    : ''

  if (!isAvailable && !attempt?.status) {
    return { label: 'Not Available Yet', tagColor: undefined }
  }

  switch (attempt?.status) {
    case 'Scheduled': {
      const scheduled = dayjs(attempt.scheduledFor)
      const now = dayjs()
      const isToday = scheduled.isSame(now, 'day')
      if (scheduled.isBefore(now) && !isToday) {
        return {
          label: showDate ? `Overdue (Scheduled: ${scheduledStr})` : 'Overdue',
          tagColor: 'gold',
        }
      }
      return {
        label: showDate ? `Scheduled: ${isToday ? 'Today' : scheduledStr}` : 'Scheduled',
        tagColor: 'purple',
      }
    }
    case 'Cancelled': {
      return { label: 'Cancelled', tagColor: 'red' }
    }
    case 'Requested': {
      return { label: showDate ? `Requested for ${requestedStr}` : 'Requested', tagColor: 'pink' }
    }
    case 'Done': {
      return {
        label: attempt.result?.name,
        tagColor: attempt.result?.isPassing ? 'green' : 'red',
      }
    }
    case 'Draft': {
      return { label: 'Draft', tagColor: 'default' }
    }
    default: {
      return {
        label:
          inspectionCoordinationType === 'SchedulingRequest' ? 'Not Requested' : 'Not Scheduled',
        tagColor: 'blue',
      }
    }
  }
}
