import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  faCloud,
  faMagicWandSparkles,
  faNotebook,
  faWandMagicSparkles,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from '@tanstack/react-query'
import { Avatar, Button, Flex, Skeleton, Tag, Tooltip } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Title from 'antd/es/typography/Title'
import dayjs from 'dayjs'
import FormControl from 'govwell-ui/components/FormControl/FormControl'
import styled from 'styled-components'
import { UserDataFragment } from 'types/graphql'

import { makeInitialFormValues } from 'src/components/Field/be-to-fe'
import { RenderedFormFieldInput } from 'src/components/FieldAndFileInputs/RenderedFormFieldInput'
import DatePicker, { DatePickerAllowedDates } from 'src/components/form/DatePicker'
import Modal from 'src/components/Modal'
import UserSelect from 'src/components/Selects/UserSelect'
import { useNotification } from 'src/components/shared/Notification/NotificationContext'
import { FormContainer } from 'src/components/shared/StyledComponents'
import UserAvatar from 'src/components/UserAvatar/UserAvatar'
import { useLogInspectionModalQuery } from 'src/fetch/logInspectionModal'
import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'
import useDisclosure from 'src/hooks/use-disclosure'
import { useMyOrg, useMyUser } from 'src/layouts/AppStateContextLayout/utils'
import { filterNil, formatUserName } from 'src/utils'
import { DateFormats, formatDate } from 'src/utils/date'
import { useAnnounceFirstInvalidField } from 'src/utils/forms'
import { QueryKey } from 'src/utils/queryClient'

import { AcceptedFileTypes } from '../../../constants'
import {
  useConvertRecordTaskInspectionAttemptToDraftMutation,
  useLogRecordTaskInspectionAttemptMutation,
  useSubmitRecordTaskInspectionAttemptDraftMutation,
  useUpdateRecordTaskInspectionAttemptDraftMutation,
} from '../../../fetch/recordTaskInspectionAttempts'
import {
  mapFieldToSingleFormFieldInput,
  mapMultipleFieldsToFormFieldInputs,
} from '../../Field/fe-to-be'
import Text, { TextSize } from '../../Typography/Text'
import { InspectionsTabModalProps } from '../types'

const SuccessAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colorSuccess};
`

type FormValues = {
  startedAt: dayjs.Dayjs
  endedAt?: dayjs.Dayjs
}

type Props = InspectionsTabModalProps & {
  recordTaskInspectionAttemptId: number
}
const LogInspectionAttemptModal = (props: Props) => {
  const {
    refetch,
    modalState,
    inspectionTemplateName,
    recordTaskInspectionId,
    recordTaskInspectionAttemptId,
    status,
    addressEntryType,
  } = props
  const { data, isLoading } = useLogInspectionModalQuery(recordTaskInspectionId)
  const inspection = data?.inspection
  const latestAttempt = inspection?.latestAttempt
  const wasLoggedWithStatus = !!status
  const wasSavedAsDraft = status === 'Draft'
  const me = useMyUser()
  let defaultInspector = latestAttempt?.inspectorUser ?? undefined
  if (!defaultInspector && me?.isInspector) {
    defaultInspector = me
  }
  const [inspectorUser, setInspectorUser] = useState<UserDataFragment | undefined>()
  useEffect(() => {
    setInspectorUser(defaultInspector)
  }, [defaultInspector])

  const { mutateAsync: logInspectionAttempt } = useLogRecordTaskInspectionAttemptMutation()
  const { mutateAsync: convertToDraft } = useConvertRecordTaskInspectionAttemptToDraftMutation()
  const { mutateAsync: updateDraft } = useUpdateRecordTaskInspectionAttemptDraftMutation()
  const { mutateAsync: submitDraft } = useSubmitRecordTaskInspectionAttemptDraftMutation()

  const inspectionTemplateFields = useMemo(
    () => inspection?.inspectionTemplate.fieldGroup?.fields || [],
    [inspection?.inspectionTemplate.fieldGroup?.fields]
  )
  const orgSlug = useMyOrg()?.slug

  const queryClient = useQueryClient()

  const initialFormValues: FormValues = useMemo(
    () =>
      wasSavedAsDraft
        ? {
            ...makeInitialFormValues({
              fields: latestAttempt?.fieldGroup?.fields ?? [],
            }),
            startedAt: dayjs(latestAttempt?.inspectionStartedAt),
            endedAt: latestAttempt?.inspectionEndedAt
              ? dayjs(latestAttempt.inspectionEndedAt)
              : undefined,
          }
        : {
            startedAt: dayjs(),
          },
    [
      wasSavedAsDraft,
      latestAttempt?.fieldGroup?.fields,
      latestAttempt?.inspectionEndedAt,
      latestAttempt?.inspectionStartedAt,
    ]
  )

  const [form] = useForm<FormValues>()
  const announceInvalidField = useAnnounceFirstInvalidField(form)
  const { isOpen: isSubmitting, open: startSubmitting, close: stopSubmitting } = useDisclosure()
  const {
    isOpen: isSubmittingDraft,
    open: startSubmittingDraft,
    close: stopSubmittingDraft,
  } = useDisclosure()
  const onOk = useCallback(
    async (args?: { saveAsDraft: true }): Promise<void> => {
      const values = form.getFieldsValue()
      if (!values || !orgSlug || isLoading) {
        return
      }
      try {
        if (!args?.saveAsDraft) {
          await form.validateFields()
          startSubmitting()
        } else {
          startSubmittingDraft()
        }
        const formFields = mapMultipleFieldsToFormFieldInputs({
          fields: filterNil(inspectionTemplateFields),
          values,
        })

        const input = {
          id: recordTaskInspectionAttemptId,
          input: {
            formFields,
            inspectorUserId: inspectorUser?.id,
            orgSlug,
            startedAt: values.startedAt?.toISOString(),
            endedAt: values.endedAt?.toISOString(),
          },
        }
        if ((!wasLoggedWithStatus || !wasSavedAsDraft) && args?.saveAsDraft) {
          await convertToDraft(input)
        } else if (wasSavedAsDraft && args?.saveAsDraft) {
          await updateDraft(input)
        } else if (wasSavedAsDraft && !args?.saveAsDraft) {
          await submitDraft(input)
        } else {
          await logInspectionAttempt(input)
        }
        await refetch()
        await queryClient.refetchQueries({ queryKey: [QueryKey.Workflow] })
        modalState.close()
      } catch (e) {
        announceInvalidField()
        console.error(e)
      } finally {
        stopSubmitting()
        stopSubmittingDraft()
      }
    },
    [
      form,
      orgSlug,
      isLoading,
      inspectionTemplateFields,
      recordTaskInspectionAttemptId,
      inspectorUser?.id,
      wasLoggedWithStatus,
      wasSavedAsDraft,
      refetch,
      queryClient,
      modalState,
      startSubmitting,
      startSubmittingDraft,
      convertToDraft,
      updateDraft,
      submitDraft,
      logInspectionAttempt,
      announceInvalidField,
      stopSubmitting,
      stopSubmittingDraft,
    ]
  )

  const { isSmallScreen } = useCurrentBreakpoint()
  const hasPreviousAttempt = !!latestAttempt?.loggedAt

  const { notification } = useNotification()
  const handleAutoFillClicked = useCallback(() => {
    const previousAttemptFieldsValue = makeInitialFormValues({
      fields: latestAttempt?.fieldGroup?.fields ?? [],
    })
    form.setFieldsValue(previousAttemptFieldsValue as FormValues)
    notification.success({
      message: 'Inspection form auto-filled!',
      description:
        'The inspection form was updated with the values from the previous inspection attempt.',
      icon: <SuccessAvatar icon={<FontAwesomeIcon icon={faMagicWandSparkles} color="white" />} />,
    })
  }, [form, latestAttempt?.fieldGroup?.fields, notification])

  return (
    <Modal
      {...modalState}
      icon={faNotebook}
      title="Log Inspection"
      okText="Log inspection"
      onOk={onOk}
      footer={
        <Flex justify="space-between" align="center" gap="24px" wrap="wrap">
          <Button
            onClick={() => onOk({ saveAsDraft: true })}
            loading={isSubmittingDraft}
            disabled={isSubmitting}
            icon={<FontAwesomeIcon icon={faCloud} />}
          >
            {wasSavedAsDraft ? 'Update draft' : 'Save as draft'}
          </Button>
          <Flex gap="8px">
            <Button onClick={modalState.close}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => onOk()}
              loading={isSubmitting}
              disabled={isSubmittingDraft}
              icon={<FontAwesomeIcon icon={faNotebook} />}
            >
              {isSmallScreen() ? 'Log' : 'Log inspection'}
            </Button>
          </Flex>
        </Flex>
      }
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <FormContainer form={form} initialValues={initialFormValues}>
          <Flex vertical gap="16px">
            <Title level={5} style={{ margin: 0 }}>
              Overview
            </Title>
            <Flex gap="24px" vertical={isSmallScreen()}>
              <Flex flex={1}>
                <UserSelect
                  label="Inspector"
                  type="Inspector"
                  value={inspectorUser}
                  onValueChange={setInspectorUser}
                  isRequired
                  width="100%"
                />
              </Flex>
              <Flex flex={1}>
                <FormControl label="Inspection Type">
                  <Text margin="4px 0px">{inspectionTemplateName}</Text>
                </FormControl>
              </Flex>
            </Flex>
            <Flex justify="space-between" gap="24px" vertical={isSmallScreen()}>
              <DatePicker
                label="Started Inspection At"
                fieldName="startedAt"
                showTime
                noMargin
                allowClear={false}
                allowedDates={DatePickerAllowedDates.All}
                required
              />
              <DatePicker
                label="End Inspection At"
                caption="If left blank, this will be set automatically when the inspection is logged"
                placeholder="When inspection is logged"
                fieldName="endedAt"
                showTime
                noMargin
                allowClear
                allowedDates={DatePickerAllowedDates.All}
              />
            </Flex>
          </Flex>
          {hasPreviousAttempt && (
            <Flex vertical gap="16px">
              <Title level={5} style={{ margin: 0 }}>
                Previous Attempt
              </Title>
              <Flex gap="24px" wrap="wrap">
                <Flex vertical gap="12px">
                  <Text strong>Result</Text>
                  <Tag color={latestAttempt.result?.isPassing ? 'green' : 'red'}>
                    {latestAttempt.result?.name}
                  </Tag>
                </Flex>
                <Flex vertical gap="12px">
                  <Text strong>Logged By</Text>
                  <Flex gap="12px">
                    <UserAvatar user={latestAttempt.loggedByUser} />
                    <Flex vertical>
                      <Text size={TextSize.Small}>{latestAttempt.loggedByUser?.title}</Text>
                      <Title level={5} style={{ margin: 0 }}>
                        {formatUserName(latestAttempt?.loggedByUser)}
                      </Title>
                    </Flex>
                  </Flex>
                </Flex>
                {!!latestAttempt.inspectionEndedAt && (
                  <Flex vertical gap="12px">
                    <Text strong>Inspection Ended At</Text>
                    {formatDate(latestAttempt.inspectionEndedAt, DateFormats.FullDateWithDayOfWeek)}
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
          <Flex vertical gap="16px">
            <Flex align="center" justify="space-between" gap="24px" wrap="wrap">
              <Title level={5} style={{ margin: 0 }}>
                Inspection Form
              </Title>
              {hasPreviousAttempt && (
                <Tooltip title="All fields in the form below will be pre-filled with the previous inspection attempt's form submission. Any values you have entered may be overwritten.">
                  <Button
                    icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
                    onClick={handleAutoFillClicked}
                  >
                    {isSmallScreen() ? 'Auto-fill' : 'Auto-fill from previous attempt'}
                  </Button>
                </Tooltip>
              )}
            </Flex>
            <Flex vertical>
              {filterNil(inspectionTemplateFields).map((f) => (
                <RenderedFormFieldInput
                  key={`field-${f.id}`}
                  formFieldInput={{
                    id: f.id,
                    input: mapFieldToSingleFormFieldInput({ field: f }),
                  }}
                  fieldNameSuffix={`${f.id}`}
                  errorMessage={f.required ? 'Please input a valid value.' : undefined}
                  acceptFileTypes={AcceptedFileTypes}
                  addressEntryType={addressEntryType}
                />
              ))}
            </Flex>
          </Flex>
        </FormContainer>
      )}
    </Modal>
  )
}

export default LogInspectionAttemptModal
