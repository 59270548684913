import { useCallback } from 'react'

import { Checkbox } from 'govwell-ui'
import SelectMenuItem from 'govwell-ui/components/Select/SelectMenuItem'
import SelectMenuItemGroupLabel from 'govwell-ui/components/Select/SelectMenuItemGroupLabel'
import { SelectOption } from 'govwell-ui/components/Select/types'
import styled from 'styled-components'

import Text from 'src/components/Typography/Text'

const StyledSelectMenuItem = styled(SelectMenuItem)`
  padding: 6px 9px;
`

type Props<TValue> = {
  id: string
  index: number
  isActive: boolean
  isSelected: boolean
  menuItemRefs: React.MutableRefObject<Map<number, HTMLElement | null>>
  option: SelectOption<TValue>
  options: SelectOption<TValue>[]
  onMouseOver: (index: number) => void
  onClick: (option: SelectOption<TValue>) => void
}
const MultiSelectItem = <TValue,>({
  id,
  index,
  isActive,
  isSelected,
  menuItemRefs,
  option,
  options,
  onMouseOver,
  onClick,
}: Props<TValue>) => {
  const handleMouseOver = useCallback(() => {
    onMouseOver(index)
  }, [index, onMouseOver])
  const handleClick = useCallback(() => {
    onClick(option)
  }, [onClick, option])

  return (
    <>
      <SelectMenuItemGroupLabel index={index} option={option} options={options} />
      <StyledSelectMenuItem
        id={id}
        onMouseOver={handleMouseOver}
        onClick={handleClick}
        ref={(el) => menuItemRefs.current.set(index, el)}
        {...(isActive
          ? {
              ['data-selected']: true, // controls option remaining highlighted when submenu is expanded
              ['aria-selected']: true,
            }
          : {})}
      >
        <div style={{ pointerEvents: 'none' }}>
          <Checkbox value={isSelected} tabIndex={-1}>
            {option.customDisplay ? (
              option.customDisplay(option.value)
            ) : (
              <Text color="inherit" strong={isActive} lineHeight="0">
                {option.label}
              </Text>
            )}
          </Checkbox>
        </div>
      </StyledSelectMenuItem>
    </>
  )
}

export default React.memo(MultiSelectItem) as typeof MultiSelectItem
