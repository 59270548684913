import { ReactNode } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Flex } from 'antd'
import Title from 'antd/es/typography/Title'
import { Input } from 'govwell-ui'

import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'

type Props = {
  actions?: React.ReactNode
  onSearchQueryChange?: (value: string) => void
  searchQuery?: string
  title?: ReactNode
  wrap?: React.CSSProperties['flexWrap']
}
const TableControls = ({ actions, onSearchQueryChange, searchQuery = '', title, wrap }: Props) => {
  const { isSmallScreen } = useCurrentBreakpoint()
  const getTitle = () => {
    if (!title) {
      return null
    }
    if (typeof title === 'string') {
      return (
        <Title level={5} style={{ margin: 0 }}>
          {title}
        </Title>
      )
    }
    return title
  }
  return (
    <Flex
      align="center"
      justify="space-between"
      gap="12px"
      wrap={wrap || (isSmallScreen() ? 'wrap' : 'nowrap')}
    >
      <Flex align="center" gap="12px">
        {getTitle()}
        {onSearchQueryChange && (
          <Input
            isClearable
            placeholder="Search"
            prefixIcon={faSearch}
            value={searchQuery}
            onValueChange={onSearchQueryChange}
            width={isSmallScreen() ? '100%' : '240px'}
          />
        )}
      </Flex>
      <Flex flex={1} justify="flex-end">
        {actions}
      </Flex>
    </Flex>
  )
}

export default React.memo(TableControls)
