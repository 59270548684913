import { useCallback } from 'react'

import {
  faColumns3,
  faDownload,
  faRedo,
  faSave,
  faSearch,
  faUndo,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex, Skeleton, Tooltip } from 'antd'
import { Input } from 'govwell-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import ManageColumnsDrawer from 'src/components/TableViews/ManageColumnsDrawer/ManageColumnsDrawer'
import { TABLE_VIEW_HEADER_BREAKPOINT_PX } from 'src/components/TableViews/util'
import Text from 'src/components/Typography/Text'
import { useCreateViewCSVMutation } from 'src/fetch/views'
import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'
import useDisclosure from 'src/hooks/use-disclosure'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'
import { RefetchFnType } from 'src/types'
import { DateFormats, formatDate } from 'src/utils/date'

import AddFilterButton from './AddFilterButton'
import FilterTag from './FilterTag'

const HeaderContainer = styled(Flex)`
  container-type: inline-size;
`

const GhostButton = styled(Button)`
  border: solid 1px;
  border-radius: 4px;
  display: flex;
  align-items: baseline;
  overflow: hidden;
`

const ViewName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SearchRow = styled(Flex)`
  @container (width < ${TABLE_VIEW_HEADER_BREAKPOINT_PX}px) {
    justify-content: flex-start;
    gap: 6px;
  }
`

const ResultsCountContainer = styled.div`
  @container (width < ${TABLE_VIEW_HEADER_BREAKPOINT_PX}px) {
    display: none;
  }
`

const FilterRow = styled(Flex)`
  @container (width < ${TABLE_VIEW_HEADER_BREAKPOINT_PX}px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
`

const FilterContainer = styled(Flex)`
  max-width: 100%;
  flex-direction: row;
  flex: 1;
`

type Props = {
  isLoadingViews: boolean
  isLoadingViewData: boolean
  onSaveChanges: () => Promise<unknown>
  onSearchQueryChange: (value: string) => void
  refetch: RefetchFnType
  searchQuery: string
  tableViewManager: TableViewManager
  isCsvEnabled: boolean
}
const TableViewHeader = ({
  isLoadingViews,
  isLoadingViewData,
  onSaveChanges,
  onSearchQueryChange,
  refetch,
  searchQuery,
  tableViewManager,
  isCsvEnabled,
}: Props) => {
  const selectedView = tableViewManager.selectedView
  const manageColumnsDrawerState = useDisclosure()
  const { isOpen: isSaving, open: startSaving, close: stopSaving } = useDisclosure()
  const handleSave = useCallback(async () => {
    startSaving()
    await onSaveChanges()
    stopSaving()
  }, [onSaveChanges, startSaving, stopSaving])

  const { isOpen: isRefetching, open: startRefetching, close: stopRefetching } = useDisclosure()
  const handleRefetch = useCallback(async () => {
    startRefetching()
    await refetch()
    stopRefetching()
  }, [refetch, startRefetching, stopRefetching])

  const { mutateAsync: createViewCSV, isPending: isDownloadingCSV } = useCreateViewCSVMutation()
  const handleDownloadCSVClicked = useCallback(async () => {
    if (!selectedView) {
      return
    }
    const res = await createViewCSV({
      fileName: `Report - ${formatDate(new Date(), DateFormats.MonthNameDateTime)}`,
      input: {
        viewInput: tableViewManager.getCreateViewInput(selectedView.protocol),
        searchQuery,
      },
    })
    window.location.href = res.createViewCsv.csvUrl
  }, [createViewCSV, selectedView, tableViewManager, searchQuery])

  const { isSmallScreen } = useCurrentBreakpoint()

  if (!selectedView || isLoadingViews) {
    return (
      <Flex vertical gap="12px">
        <Flex gap="6px">
          <Skeleton.Input active />
          <Skeleton.Button active />
        </Flex>
        <Skeleton.Input active style={{ height: '22px' }} />
      </Flex>
    )
  }

  return (
    <HeaderContainer vertical justify="stretch" gap="12px">
      <SearchRow justify="space-between">
        <Flex align="center" gap="6px">
          <Input
            isClearable
            placeholder="Search"
            prefixIcon={faSearch}
            value={searchQuery}
            onValueChange={onSearchQueryChange}
            width={isSmallScreen() ? '170px' : '240px'}
          />
          <AddFilterButton filterManager={selectedView.filterManager} />
        </Flex>
        <Flex align="center" gap="6px">
          {selectedView.hasUnsavedChanges && (
            <ResultsCountContainer>
              {isLoadingViewData && (
                <Skeleton.Input
                  active
                  style={{
                    height: '22px',
                    width: '80px',
                    minWidth: '80px',
                  }}
                />
              )}
              {!isLoadingViewData && (
                <Flex style={{ marginRight: '3px' }}>
                  {selectedView.totalCount === 0 && (
                    <Text colorToken="colorTextSecondary" whiteSpace="nowrap">
                      No results
                    </Text>
                  )}
                  {selectedView.totalCount > 0 && (
                    <Text strong whiteSpace="nowrap">
                      {selectedView.totalCount === 1 && '1 Result'}
                      {selectedView.totalCount > 1 &&
                        `${Intl.NumberFormat('en-US').format(selectedView.totalCount)} Results`}
                    </Text>
                  )}
                </Flex>
              )}
            </ResultsCountContainer>
          )}
          <Tooltip title="Refresh results">
            <Button
              icon={<FontAwesomeIcon icon={faRedo} />}
              type="default"
              onClick={handleRefetch}
              loading={isRefetching}
            />
          </Tooltip>
          {isCsvEnabled && (
            <Tooltip title="Download CSV">
              <Button
                icon={<FontAwesomeIcon icon={faDownload} />}
                type="default"
                onClick={handleDownloadCSVClicked}
                loading={isDownloadingCSV}
              />
            </Tooltip>
          )}
          <Tooltip title="Manage Columns">
            <Button
              icon={<FontAwesomeIcon icon={faColumns3} />}
              type="default"
              onClick={manageColumnsDrawerState.open}
            />
          </Tooltip>
          {manageColumnsDrawerState.isOpen && (
            <ManageColumnsDrawer
              modalState={manageColumnsDrawerState}
              tableViewManager={tableViewManager}
              view={selectedView}
            />
          )}
        </Flex>
      </SearchRow>
      <FilterRow justify="space-between" gap="12px">
        <FilterContainer wrap="wrap" gap="8px">
          {selectedView.filterManager.filters.map((f) => (
            <FilterTag
              key={f.columnTemplateGeneratedId}
              filter={f}
              filterManager={selectedView.filterManager}
            />
          ))}
          {
            ' ' /* this space necessary to keep save changes right-aligned when no filters are applied */
          }
        </FilterContainer>
        <Flex gap="9px">
          {selectedView.hasUnsavedChanges && (
            <>
              <Button
                icon={<FontAwesomeIcon icon={faUndo} />}
                size="small"
                type="default"
                onClick={() => {
                  runInAction(() => {
                    selectedView.reset()
                  })
                }}
              >
                Reset
              </Button>
              <GhostButton
                icon={<FontAwesomeIcon icon={faSave} />}
                size="small"
                onClick={handleSave}
                loading={isSaving}
                type="link"
              >
                <span>Save changes to</span>&nbsp;
                <ViewName>{selectedView.name}</ViewName>
              </GhostButton>
            </>
          )}
        </Flex>
      </FilterRow>
    </HeaderContainer>
  )
}

export default observer(TableViewHeader)
