import { makeAutoObservable } from 'mobx'
import {
  ViewColumnDataType,
  ViewColumnTemplateFragment,
  ViewColumnType,
  ViewColumnUsedInViewFragment,
  ViewFilterOptionFragment,
} from 'types/graphql'

export class TableColumnTemplate {
  public readonly columnType: ViewColumnType
  public readonly dataType: ViewColumnDataType
  public readonly filterOptions: ViewFilterOptionFragment[]
  public readonly generatedId: string
  public readonly includeByDefault: boolean
  public readonly isFilterable: boolean
  public readonly isSortable: boolean
  public readonly isNullable: boolean
  public readonly labelSentenceCase: string
  public readonly labelTitleCase: string
  public readonly usedInViews: ViewColumnUsedInViewFragment[]

  constructor(args: {
    columnType: ViewColumnType
    dataType: ViewColumnDataType
    generatedId: string
    labelSentenceCase: string
    labelTitleCase: string
    filterOptions: ViewFilterOptionFragment[] | undefined
    includeByDefault: boolean
    isFilterable: boolean
    isSortable: boolean
    isNullable: boolean
    usedInViews: ViewColumnUsedInViewFragment[]
  }) {
    this.columnType = args.columnType
    this.dataType = args.dataType
    this.generatedId = args.generatedId
    this.labelSentenceCase = args.labelSentenceCase
    this.labelTitleCase = args.labelTitleCase
    this.filterOptions = args.filterOptions ?? []
    this.includeByDefault = args.includeByDefault
    this.isFilterable = args.isFilterable
    this.isSortable = args.isSortable
    this.isNullable = args.isNullable
    this.usedInViews = args.usedInViews
    makeAutoObservable(this)
  }

  static fromProtocol(protocol: ViewColumnTemplateFragment) {
    return new this({
      columnType: protocol.columnType,
      dataType: protocol.dataType,
      generatedId: protocol.generatedId,
      labelSentenceCase: protocol.labelSentenceCase,
      labelTitleCase: protocol.labelTitleCase,
      filterOptions: protocol.filterOptions ?? [],
      includeByDefault: protocol.includeByDefault,
      isFilterable: protocol.isFilterable,
      isSortable: protocol.isSortable,
      isNullable: !!protocol.isNullable,
      usedInViews: protocol.usedInViews,
    })
  }
}
