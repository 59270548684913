import { useCallback, useMemo } from 'react'

import { MultiSelect } from 'govwell-ui'
import { SelectOption } from 'govwell-ui/components/Select/types'
import { FieldFragment } from 'types/graphql'

import { useAllFieldTemplatesInRecordTypeQuery } from 'src/fetch/recordTypes'
import { filterNil } from 'src/utils'

type Props = {
  onChange: (values: FieldFragment[] | undefined) => void
  value: FieldFragment[] | undefined
  recordTypeId: number | undefined
}
const FieldTemplateMultiSelect = ({ onChange, value, recordTypeId }: Props) => {
  const { data, isLoading } = useAllFieldTemplatesInRecordTypeQuery(recordTypeId)
  const fieldType = value?.[0]?.type

  const options: SelectOption<FieldFragment>[] = useMemo(
    () =>
      filterNil(
        data?.recordType?.recordTemplates?.flatMap((rt) =>
          rt.applicationFields
            ?.filter((f) => !fieldType || f.type === fieldType)
            ?.sort((a, b) => (a.label || '').localeCompare(b.label || ''))
            .map((f) => ({
              group: `${rt.name || 'No Name'}`,
              label: `${rt.name || 'No Name'}: ${f.label}`,
              customDisplay: (v: FieldFragment) => v.label,
              value: f,
            }))
        ) ?? []
      ),
    [data?.recordType?.recordTemplates, fieldType]
  )
  const optionsById = useMemo(
    () => new Map<number, SelectOption<FieldFragment>>(options.map((o) => [o.value.id, o])),
    [options]
  )
  const selectedOptions = useMemo(
    () => filterNil(value?.map((v) => optionsById.get(v.id)) ?? []),
    [optionsById, value]
  )

  const getOptionKey = useCallback((o: SelectOption<FieldFragment>) => o.value.id, [])

  const handleSelectedOptionsChange = useCallback(
    (newSelectedOptions: SelectOption<FieldFragment>[] | undefined) => {
      onChange(newSelectedOptions?.map((o) => o.value))
    },
    [onChange]
  )

  return (
    <MultiSelect<FieldFragment>
      caption="All fields selected must share the same field type."
      getOptionKey={getOptionKey}
      isDisabled={isLoading}
      isLoading={isLoading}
      isRequired
      label="Fields to Display"
      onSelectedOptionsChange={handleSelectedOptionsChange}
      options={options}
      placeholder={isLoading ? 'Loading fields...' : undefined}
      selectedOptions={selectedOptions}
    />
  )
}

export default React.memo(FieldTemplateMultiSelect)
