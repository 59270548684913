import { Flex } from 'antd'

import Text, { TextSize } from '../Typography/Text'

type Props = {
  inspectionTemplateName: string
  schedulingNotes: string
}
export const InspectionMetadata = (props: Props) => {
  const { inspectionTemplateName, schedulingNotes } = props

  return (
    <Flex vertical gap="24px">
      <Flex vertical>
        <Text size={TextSize.Base} underline>
          Inspection Type
        </Text>
        <Text size={TextSize.Base} margin="0 0 24px">
          {inspectionTemplateName}
        </Text>
      </Flex>
      {schedulingNotes && (
        <Flex vertical gap="12px">
          <Text size={TextSize.Base} underline>
            Notes from Applicant
          </Text>
          <Text size={TextSize.Base} margin="0 0 24px">
            {schedulingNotes}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
