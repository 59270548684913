import { useCallback, useMemo } from 'react'

import { Select } from 'govwell-ui'
import { SelectOption } from 'govwell-ui/components/Select/types'
import { observer } from 'mobx-react-lite'
import { UserDataFragment } from 'types/graphql'

import { useMyOrgActiveInspectors } from 'src/layouts/AppStateContextLayout/utils'
import { formatUserName } from 'src/utils'

type UserType = 'Inspector'
type Props = {
  isRequired?: boolean
  label?: string
  onValueChange: (value: UserDataFragment | undefined) => void
  type: UserType // dummy prop to communicate this is only configured to select inspectors right now, please feel free to add other types
  value: UserDataFragment | null | undefined
  width?: React.CSSProperties['width']
}
const UserSelect = ({ isRequired, label, onValueChange, value, width }: Props) => {
  const getOptionFromUser = useCallback(
    (user: UserDataFragment): SelectOption<UserDataFragment> => ({
      label: formatUserName(user),
      value: user,
    }),
    []
  )

  const users = useMyOrgActiveInspectors()
  const options: SelectOption<UserDataFragment>[] = useMemo(
    () => users.map((u) => getOptionFromUser(u)),
    [getOptionFromUser, users]
  )
  const selectedOption = useMemo(
    () => (value ? getOptionFromUser(value) : undefined),
    [getOptionFromUser, value]
  )

  const handleChange = useCallback(
    (option: SelectOption<UserDataFragment> | undefined) => {
      onValueChange(option?.value)
    },
    [onValueChange]
  )

  return (
    <Select
      getOptionKey={(o) => o.value.id}
      isRequired={isRequired}
      label={label}
      onSelectedOptionChange={handleChange}
      options={options}
      placeholder="Select user"
      selectedOption={selectedOption}
      width={width}
    />
  )
}

export default observer(UserSelect)
