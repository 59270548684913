import { Header, flexRender } from '@tanstack/react-table'
import { Flex, Tooltip } from 'antd'
import { useTableContext } from 'govwell-ui/components/Table/context'
import TableSortIndicator from 'govwell-ui/components/Table/TableSortIndicator'
import { Size } from 'govwell-ui/types'
import isNil from 'lodash.isnil'
import styled from 'styled-components'

import Text, { TextSize, getFontSize } from 'src/components/Typography/Text'

const StyledTH = styled.th<{
  $hasBorderRadius: boolean
  $horizontalPaddingOverride?: React.CSSProperties['paddingLeft']
  $isSortable?: boolean
  $size?: Size
  $textAlign?: React.CSSProperties['textAlign']
  $width: React.CSSProperties['width']
}>`
  width: ${({ $width }) => $width};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${getFontSize(TextSize.Base)}px;
  position: relative;
  text-align: ${({ $textAlign }) => $textAlign ?? 'left'};
  font-weight: ${({ $size }) => ($size === 'sm' ? 500 : 600)};
  color: ${({ theme }) => theme.colorText};
  padding: ${({ $horizontalPaddingOverride, $size }) => {
    const verticalPadding = $size === 'sm' ? '8px' : '16px'
    const horizontalPadding = isNil($horizontalPaddingOverride) ? '8px' : $horizontalPaddingOverride
    return `${verticalPadding} ${horizontalPadding}`
  }};
  &:first-child {
    padding-left: ${({ $horizontalPaddingOverride }) =>
      !isNil($horizontalPaddingOverride) ? $horizontalPaddingOverride : '12px'};
  }
  &:last-child {
    padding-right: ${({ $horizontalPaddingOverride }) =>
      !isNil($horizontalPaddingOverride) ? $horizontalPaddingOverride : '12px'};
  }
  cursor: ${({ $isSortable: $isSortingEnabled }) => ($isSortingEnabled ? 'pointer' : 'default')};

  border-top: solid 1px ${({ theme }) => theme.colorSplit};
  border-bottom: solid 1px ${({ theme }) => theme.colorSplit};
  &:first-child {
    ${({ $hasBorderRadius }) => $hasBorderRadius && 'border-top-left-radius: 6px;'}
    border-left: solid 1px ${({ theme }) => theme.colorSplit};
  }
  &:last-child {
    ${({ $hasBorderRadius }) => $hasBorderRadius && 'border-top-right-radius: 6px;'}
    border-right: solid 1px ${({ theme }) => theme.colorSplit};
  }
`

type Props<TData> = {
  hasBorderRadius: boolean
  header: Header<TData, unknown>
}
const TableHeaderCell = <TData,>({ hasBorderRadius, header }: Props<TData>) => {
  const isSortable = header.column.getCanSort()
  const { size } = useTableContext()
  const children = flexRender(header.column.columnDef.header, header.getContext())
  const width = header.column.columnDef.meta?.width
  return (
    <Tooltip
      title={
        isSortable
          ? `Click to ${
              header.column.getIsSorted() === 'asc'
                ? 'sort descending'
                : header.column.getIsSorted() === 'desc'
                  ? 'remove sorting'
                  : 'sort ascending'
            }`
          : ''
      }
    >
      <StyledTH
        colSpan={header.colSpan}
        $hasBorderRadius={hasBorderRadius}
        $horizontalPaddingOverride={
          header.column.columnDef.meta?.dangerouslyOverrideHorizontalPadding
        }
        $isSortable={isSortable}
        $size={size}
        $textAlign={header.column.columnDef.meta?.textAlign}
        $width={width ? width : 'auto'}
        onClick={header.column.getToggleSortingHandler()}
      >
        <Flex gap="6px" align="center">
          <Flex
            gap="6px"
            align="center"
            justify={
              header.column.columnDef.meta?.textAlign === 'right' ? 'flex-end' : 'flex-start'
            }
          >
            {typeof children === 'string' ? <Text>{children}</Text> : children}
          </Flex>
          {isSortable && <TableSortIndicator column={header.column} />}
        </Flex>
      </StyledTH>
    </Tooltip>
  )
}

export default TableHeaderCell
