import { makeAutoObservable, runInAction } from 'mobx'
import { ViewFilterFragment } from 'types/graphql'

import { TableViewColumnTemplateStore } from 'src/models/TableViews/TableViewColumnTemplateStore'

import { TableColumnTemplate } from './TableColumnTemplate'
import { TableViewFilter } from './TableViewFilter'

export class FilterManager {
  private _columnTemplateStore: TableViewColumnTemplateStore

  private _filtersByColumnGeneratedId: Map<string, TableViewFilter> = new Map<
    string,
    TableViewFilter
  >()

  private _addFilterForColumnTemplate(columnTemplate: TableColumnTemplate): TableViewFilter {
    let filter = this._filtersByColumnGeneratedId.get(columnTemplate.generatedId)
    if (!filter) {
      filter = new TableViewFilter({
        columnTemplateStore: this._columnTemplateStore,
        protocol: {
          columnTemplateGeneratedId: columnTemplate.generatedId,
          bool: undefined,
          optionsSelected: undefined,
        },
      })
      this._filtersByColumnGeneratedId.set(columnTemplate.generatedId, filter)
    }
    return filter
  }

  constructor(args: {
    filters: ViewFilterFragment[]
    columnTemplateStore: TableViewColumnTemplateStore
  }) {
    this._columnTemplateStore = args.columnTemplateStore
    runInAction(() => {
      const filterEntries: [string, TableViewFilter][] = []
      args.filters.forEach((f) => {
        filterEntries.push([
          f.columnTemplateGeneratedId,
          new TableViewFilter({
            columnTemplateStore: this._columnTemplateStore,
            protocol: { ...f, columnTemplateGeneratedId: f.columnTemplateGeneratedId },
          }),
        ])
      })
      this._filtersByColumnGeneratedId = new Map(filterEntries)

      // Make sure every column type is represented in the map, even if none were saved to the db because they were not populated
      this.columnTemplates.forEach((ct) => {
        this._addFilterForColumnTemplate(ct)
      })
    })
    makeAutoObservable(this)
  }

  public get columnTemplates(): TableColumnTemplate[] {
    return this._columnTemplateStore.columnTemplates
      .map((ct) => TableColumnTemplate.fromProtocol(ct))
      .sort((a, b) => a.labelSentenceCase.localeCompare(b.labelSentenceCase))
  }

  public get filterableColumnTemplates(): TableColumnTemplate[] {
    return this.columnTemplates.filter((ct) => {
      if (!ct.isFilterable) {
        return false
      }
      if (ct.columnType === 'RecordField') {
        return ct.filterOptions.length > 0
      }
      return true
    })
  }

  public get sortableColumnTemplates(): TableColumnTemplate[] {
    return this.columnTemplates.filter((ct) => ct.isSortable)
  }

  public get populatedFilters(): TableViewFilter[] {
    return this.filters.filter((f) => f.isPopulated)
  }

  public get filters(): TableViewFilter[] {
    return Array.from(this._filtersByColumnGeneratedId.values()).filter((f) => !!f.columnTemplate)
  }

  public get hasUnsavedChanges(): boolean {
    return this.filters.some((f) => f.hasUnsavedChanges)
  }

  public clearAllFilters() {
    this._filtersByColumnGeneratedId = new Map<string, TableViewFilter>()
  }

  public getFilterByColumnGeneratedId(generatedId: string): TableViewFilter {
    let filter = this._filtersByColumnGeneratedId.get(generatedId)
    if (!filter) {
      const columnTemplate = this._columnTemplateStore.getColumnTemplateByGeneratedId(generatedId)
      if (columnTemplate === undefined) {
        throw new Error('Column template matching generated id not found')
      }
      filter = this._addFilterForColumnTemplate(TableColumnTemplate.fromProtocol(columnTemplate))
    }
    return filter
  }

  public reset() {
    this._filtersByColumnGeneratedId.forEach((f) => {
      f.reset()
    })
  }

  public save() {
    this._filtersByColumnGeneratedId.forEach((f) => {
      f.save()
    })
  }
}
