import isNil from 'lodash.isnil'
import { observer } from 'mobx-react-lite'

import {
  DateFilterDirectionNamesByDirection,
  getLabelForDateFilterDelayType,
} from 'src/components/TableViews/util'
import { useMyOrg } from 'src/layouts/AppStateContextLayout/utils'
import { TableViewDateFilter } from 'src/models/TableViews/TableViewDateFilter'
import { TableViewFilter } from 'src/models/TableViews/TableViewFilter'
import { filterNilOrEmptyString } from 'src/utils'
import { DateFormats, formatDate, formatDateInTimeZone } from 'src/utils/date'

type Props = {
  filter: TableViewFilter
}
const FilterTagValue = ({ filter }: Props) => {
  const org = useMyOrg()
  const timeZone = org?.timeZone
  const paymentProviderFormatted = org?.paymentProviderFormatted

  if (!isNil(filter.bool)) {
    return filter.bool ? 'True' : 'False'
  }
  if (TableViewDateFilter.isDatePopulated(filter.date)) {
    const formatFn = (date: string) => {
      const dateFormat = DateFormats.MonthNameDateNoTime
      if (filter.columnTemplate?.dataType === 'Date') {
        // Semantic dates should not be time zone-adjusted
        return formatDate(date, dateFormat)
      }
      return formatDateInTimeZone(date, timeZone, dateFormat)
    }
    switch (filter.date.type) {
      case 'Exact': {
        const start = filter.date.exactStart
        const end = filter.date.exactEnd
        if (end && !start) {
          return `On or before ${formatFn(end)}`
        }
        if (start && !end) {
          return `On or after ${formatFn(start)}`
        }
        if (!start || !end) {
          return '-' // Should not happen
        }
        return `${formatFn(start)} - ${formatFn(end)}`
      }
      case 'IsNotNull':
        return 'Is not empty'
      case 'IsNull':
        return 'Is empty'
      case 'RelativeToToday':
        if (
          !filter.date.relativeToTodayDirection ||
          !filter.date.relativeToTodayDelayType ||
          isNil(filter.date.relativeToTodayCount)
        ) {
          return '-' // Should not happen
        }
        return `${
          DateFilterDirectionNamesByDirection[filter.date.relativeToTodayDirection]
        } ${filter.date.relativeToTodayCount} ${getLabelForDateFilterDelayType(
          filter.date.relativeToTodayDelayType,
          filter.date.relativeToTodayCount > 1
        )}`
      case 'Today':
        return 'Today'
    }
    return '-'
  }
  if (!isNil(filter.isNull)) {
    return filter.isNull ? 'Is empty' : 'Is not empty'
  }

  if (!isNil(filter.optionsSelected)) {
    return filterNilOrEmptyString(
      filter.optionsSelected.map((selectedOption) =>
        filter.getOptionDisplayValue(selectedOption, paymentProviderFormatted ?? '')
      )
    )
      .sort()
      .join(', ')
  }
}

export default observer(FilterTagValue)
