import { ReactNode } from 'react'

import styled from 'styled-components'

import { getFieldNameSuffixForFormSubmittal } from 'src/pages/RecordPage/Record/tabs/DetailsTab/util'

import {
  Contact,
  FieldContactInput,
  FieldFragment,
  FileFragment,
  ForeignValue,
  FormFieldInput,
  State,
} from '../../../types/graphql'
import { FormFieldNamePrefix } from '../../constants'
import { UploadMultipleFilesFileUploadState } from '../../hooks/use-files-upload-state'
import { formatPhoneNumber, mapStrsToInts, maybeMakeInt } from '../../utils'
import { ContactFieldName } from '../FieldAndFileInputs/modals/FieldInputUtil'
import { ContactAddressFields } from '../FieldAndFileInputs/RenderedContactInputs'
import { AddressAutocompleteFieldValues } from '../form/AddressAutocomplete'
import {
  mapRecordResultToAutocompleteOption,
  RecordAutocompleteFieldValues,
} from '../form/shared/RecordAutocompleteCell/RecordAutocomplete'
import { RecordCollectionSelectValues } from '../form/shared/RecordCollectionSelect'
import { ViolationTypeSelectFieldValues } from '../form/shared/ViolationTypeSelectCell'

import { FieldDisplay } from './FieldDisplay'
import { destructureFieldParameters } from './util'

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

interface MapFrontEndFormValueToBackendResponse {
  formFieldInput: Partial<FormFieldInput>
  display: ReactNode
}

const getFieldKey = (
  field: FieldFragment,
  getFieldNameSuffix?: (field: FieldFragment) => string
) => {
  const suffix = getFieldNameSuffix?.(field) ?? getFieldNameSuffixForFormSubmittal(field)
  return `${FormFieldNamePrefix}${suffix}`
}

export const mapFrontEndFormValueToSpecificColumnValues = (
  field: FieldFragment,
  values: Record<string, unknown>,
  getFieldNameSuffix?: (field: FieldFragment) => string
): MapFrontEndFormValueToBackendResponse => {
  function getRawVal<ReturnType>(): ReturnType {
    const key = getFieldKey(field, getFieldNameSuffix)
    return values?.[key] as ReturnType
  }

  const { type, allowMultiple } = field

  switch (type) {
    case 'ShortText': {
      if (allowMultiple) {
        const strs = getRawVal<string[]>()
        return {
          formFieldInput: { value: { strs }, strs },
          display: <FieldDisplay input={{ type, strs }} />,
        }
      }

      const str = getRawVal<string>()

      return {
        formFieldInput: { value: { str }, str },
        display: <FieldDisplay input={{ type, str }} />,
      }
    }
    case 'LongText':
    case 'Radio':
    case 'Date': {
      const rawVal = getRawVal<string>()
      return {
        formFieldInput: {
          value: { str: rawVal },
          str: rawVal,
        },
        display: <FieldDisplay input={{ type, str: rawVal }} />,
      }
    }
    case 'Dropdown': {
      const rawVal = getRawVal<string | string[]>()
      const processedVal = Array.isArray(rawVal) ? rawVal : [rawVal].filter((a) => !!a)
      return {
        formFieldInput: {
          value: { strs: processedVal },
          strs: processedVal,
        },
        display: <FieldDisplay input={{ type, strs: processedVal }} />,
      }
    }
    case 'Currency':
    case 'Number': {
      const rawVal = getRawVal<string>()
      const num = rawVal ? parseFloat(rawVal) : undefined
      return {
        formFieldInput: {
          value: { num },
          num,
        },
        display: <FieldDisplay input={{ type, num }} />,
      }
    }
    case 'File': {
      const file = getRawVal<FileFragment>()
      return {
        formFieldInput: {
          ...(file
            ? {
                value: { fileId: file?.id },
                fileId: file?.id,
              }
            : {}),
        },
        display: <FieldDisplay input={{ type, file }} />,
      }
    }
    case 'Signature': {
      const file = getRawVal<FileFragment>()
      return {
        formFieldInput: {
          value: { fileId: file?.id },
          fileId: file?.id,
        },
        display: <FieldDisplay input={{ type, file }} />,
      }
    }
    case 'Files': {
      const states = getRawVal<UploadMultipleFilesFileUploadState[]>()
      const files = states?.flatMap((s) => s?.govWellFile || [])
      const fileIds = files?.flatMap((f) => f?.id || [])
      return {
        formFieldInput: {
          value: { fileIds },
          fileIds,
        },
        display: <FieldDisplay input={{ type, files }} />,
      }
    }
    case 'CodeBookItem': {
      const rawVal = getRawVal<number | number[] | null | undefined>()

      const codeBookItemIds = Array.isArray(rawVal) ? rawVal : rawVal ? [rawVal] : []
      return {
        formFieldInput: {
          codeBookItemIds,
        },
        display: '',
      }
    }
    case 'InspectionResult': {
      const rawVal = getRawVal<string>()
      const inspectionResultId = parseInt(rawVal)
      return {
        formFieldInput: {
          value: { inspectionResultId },
          inspectionResultId,
        },
        display: '',
      }
    }
    case 'Checkbox': {
      const rawVal = getRawVal<string[]>()
      const parameters = destructureFieldParameters(field.parameters || {})
      const checked =
        rawVal && parameters?.checkboxText ? rawVal?.includes(parameters?.checkboxText) : false
      return {
        formFieldInput: {
          value: { checked },
          checked,
        },
        display: <FieldDisplay input={{ type, checked }} />,
      }
    }
    case 'Contact': {
      const contact = mapFrontEndFormValuesToContact(
        values as Record<string, Contact>,
        getFieldKey(field, getFieldNameSuffix)
      )
      return {
        formFieldInput: {
          contactInput: contact,
        },
        display: <FieldDisplay input={{ type, contact }} />,
      }
    }
    case 'Record': {
      if (allowMultiple) {
        const rawVal = getRawVal<RecordAutocompleteFieldValues[]>()
        if (!rawVal?.length) {
          return { formFieldInput: {}, display: '-' }
        }
        const recordIds = rawVal?.map((v) => parseInt(v?.selectedId)).filter((id) => !!id)

        if (!recordIds?.length) {
          return { formFieldInput: {}, display: '-' }
        }
        const labels = rawVal?.map((v) => v?.label).filter((label) => !!label)
        return {
          formFieldInput: { value: { recordIds }, recordIds },
          display: (
            <List>
              {labels.map((label, index) => (
                <div key={`label=${index}`}>{label}</div>
              ))}
            </List>
          ),
        }
      }
      const rawVal = getRawVal<RecordAutocompleteFieldValues>()
      const recordId = parseInt(rawVal?.selectedId)
      const rawRecord = rawVal?.options?.find(
        (o) => mapRecordResultToAutocompleteOption(o)?.id === rawVal?.selectedId
      )
      if (!rawRecord) {
        return { formFieldInput: {}, display: '-' }
      }
      const option = mapRecordResultToAutocompleteOption(rawRecord)
      return {
        formFieldInput: {
          value: { recordId },
          recordId,
        },
        display: option.label,
      }
    }
    case 'Address': {
      const parseVals = (vals: AddressAutocompleteFieldValues) => {
        const label = vals?.manualAddress || vals?.label
        return {
          locationSnapshotId: vals?.locationSnapshotId,
          str: vals?.manualAddress || (vals?.label as string),
          isMainAddress: vals?.isMainAddress,
          label: label as string,
        }
      }
      if (allowMultiple) {
        const rawVals = getRawVal<AddressAutocompleteFieldValues[]>() || []
        const parsed = rawVals.map(parseVals)

        if (parsed.every((p) => !p.label)) {
          return { formFieldInput: {}, display: '-' }
        }

        return {
          formFieldInput: {
            locationInputs: parsed.map((parsedInfo) => ({
              locationSnapshotId: parsedInfo.locationSnapshotId,
              str: parsedInfo.str,
              isMainAddress: parsedInfo.isMainAddress,
            })),
          },
          display: (
            <List>
              {parsed.map((p, index) => (
                <div key={`location-${index}`}>{p.label}</div>
              ))}
            </List>
          ),
        }
      }

      const rawVal = getRawVal<AddressAutocompleteFieldValues>()
      const parsed = parseVals(rawVal)
      return {
        formFieldInput: {
          locationSnapshotId: parsed?.locationSnapshotId,
          str: parsed?.str,
        },
        display: <FieldDisplay input={{ type, str: parsed.label }} />,
      }
    }
    case 'ViolationType': {
      const rawVal = getRawVal<ViolationTypeSelectFieldValues>()
      const selectedIds: string[] = rawVal?.selectedIds as string[]
      const violationTypes = selectedIds?.map((id, index) => ({
        id: maybeMakeInt(id),
        name: rawVal?.typeNames?.[index],
      }))
      const violationTypeIds = mapStrsToInts(selectedIds || [])
      return {
        formFieldInput: {
          violationTypeIds,
        },
        display: <FieldDisplay input={{ type, violationTypes }} />,
      }
    }
    case 'RecordCollection': {
      const rawVal = getRawVal<RecordCollectionSelectValues>()
      const recordCollectionIdsRaw = Array.isArray(rawVal?.selectedIds)
        ? rawVal?.selectedIds
        : [rawVal?.selectedIds]
      const recordCollectionIds: number[] = recordCollectionIdsRaw
        .map((id) => maybeMakeInt(id))
        .filter((id) => !!id)
      const recordCollections = recordCollectionIds?.map((id, index) => ({
        id: maybeMakeInt(id),
        name: rawVal?.recordCollectionNames?.[index],
      }))
      return {
        formFieldInput: { recordCollectionIds },
        display: <FieldDisplay input={{ type, recordCollections }} />,
      }
    }
    case 'ForeignValue': {
      const foreignValue = getRawVal<ForeignValue>()
      return {
        formFieldInput: { foreignValue },
        display: <FieldDisplay input={{ type, foreignValue }} />,
      }
    }
    default: {
      return {
        formFieldInput: {
          value: {},
        },
        display: '',
      }
    }
  }
}

type FeToBEParamsSingle = {
  field: FieldFragment
  values?: Record<string, unknown>
  getBasedOnFieldId?: (field: FieldFragment) => number | null | undefined
  getFieldNameSuffix?: (field: FieldFragment) => string
}

export const mapFieldToSingleFormFieldInput = (params: FeToBEParamsSingle): FormFieldInput => {
  const {
    field,
    field: {
      label,
      parameters,
      required,
      tooltip,
      type,
      foreignValueType,
      id,
      allowMultiple,
      recordTemplates,
      order,
    },
    values,
    getBasedOnFieldId,
    getFieldNameSuffix,
  } = params
  const recordTemplateIdParams = recordTemplates?.map((rt) => rt.id)
  return {
    ...mapFrontEndFormValueToSpecificColumnValues(field, values || {}, getFieldNameSuffix)
      .formFieldInput,
    label,
    required,
    tooltip,
    type,
    foreignValueType,
    parameters,
    basedOnFieldId: getBasedOnFieldId?.(field) || id,
    allowMultiple,
    recordTemplateIdParams,
    order,
  }
}

interface FeToBEParamsMultiple {
  fields: FieldFragment[]
  values?: Record<string, unknown>
  getBasedOnFieldId?: (field: FieldFragment) => number | null | undefined
  getFieldNameSuffix?: (field: FieldFragment) => string
}

export const mapMultipleFieldsToFormFieldInputs = (
  params: FeToBEParamsMultiple
): FormFieldInput[] => {
  const { fields, values, getBasedOnFieldId, getFieldNameSuffix } = params
  return fields.map((field) =>
    mapFieldToSingleFormFieldInput({
      field,
      values,
      getBasedOnFieldId,
      getFieldNameSuffix,
    })
  )
}

export const mapFrontEndFormValuesToContact = (
  values: Record<string, Contact>,
  prefix = FormFieldNamePrefix
): FieldContactInput => {
  const get = (fieldName: string) => values?.[prefix]?.[fieldName as keyof Contact] as string
  const getAddressField = (fieldName: string) =>
    values?.[prefix]?.[fieldName as keyof Contact] as string
  const phone = get(ContactFieldName.PhoneNumber)
  const fax = get(ContactFieldName.FaxNumber)
  const addressLine1 = getAddressField(ContactAddressFields.Line1)
  const addressLine2 = getAddressField(ContactAddressFields.Line2)
  const city = getAddressField(ContactAddressFields.City)
  const state = getAddressField(ContactAddressFields.State) as State
  const zip = getAddressField(ContactAddressFields.Zip)

  return {
    firstName: get(ContactFieldName.FirstName),
    lastName: get(ContactFieldName.LastName),
    companyName: get(ContactFieldName.CompanyName),
    phoneNumber: phone ? formatPhoneNumber(phone) : '',
    faxNumber: fax ? formatPhoneNumber(fax) : '',
    email: get(ContactFieldName.Email),
    title: get(ContactFieldName.Title),
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
  }
}
