import { Flex, Skeleton } from 'antd'
import styled from 'styled-components'

export const StyledInputSkeleton = styled(Skeleton.Input)`
  height: 16px;
  width: 140px !important;
  min-width: 140px !important;
  > .ant-skeleton-input {
    height: 16px;
    max-height: 16px;
    width: 140px !important;
    min-width: 140px !important;
  }
`

export const FormSelectLoadingPlaceholder = () => (
  <Flex vertical justify="center">
    <StyledInputSkeleton active />
  </Flex>
)
