import { useMemo } from 'react'

import SelectGroup, { OptionGroupType, Props as SelectProps } from 'src/components/form/SelectGroup'
import { FormSelectLoadingPlaceholder } from 'src/components/form/shared/LoadingPlaceholder'
import { useCodeBooksQuery } from 'src/fetch/codeBooks'

type Props = Omit<SelectProps<number>, 'options'>
const CodeBookItemSelect = (props: Props) => {
  const { data, isLoading } = useCodeBooksQuery()
  const options: OptionGroupType<number>[] = useMemo(
    () =>
      data?.codeBooks?.flatMap((book) => ({
        label: book.name,
        options:
          book.items.flatMap((item) => ({
            label: item.formattedName,
            value: item.id,
          })) ?? [],
      })) ?? [],
    [data?.codeBooks]
  )

  return (
    <SelectGroup<number>
      noMargin
      options={options}
      loading={isLoading}
      {...props}
      placeholder={
        isLoading ? <FormSelectLoadingPlaceholder /> : props.placeholder || 'Select code book item'
      }
      fieldName={isLoading ? '' : props.fieldName}
    />
  )
}

export default React.memo(CodeBookItemSelect)
