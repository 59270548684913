export const RecordTemplateOptionFragment = gql`
  fragment RecordTemplateOptionFragment on RecordTemplate {
    id
    status
    uuid
    createdAt
    name
    recordType {
      id
      recordName
      name
      category
    }
  }
`

export const RecordTemplatePickFragment = gql`
  fragment RecordTemplatePickFragment on RecordTemplate {
    id
    uuid
    name
    tagline
    publishable
    whenRequired
    applicantType
    icon
    visibleOnModulePickPage
    visibleOnOrganizationHomepage
    applicationGroup {
      ...RecordTypeApplicationGroupFragment
    }
  }
`
