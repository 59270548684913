import { useContext } from 'react'

import { Size } from 'govwell-ui/types'

type TableContextType = {
  size?: Size
  uuid: string
}
export const TableContext = React.createContext<TableContextType>({} as TableContextType)
export const useTableContext = () => useContext(TableContext)
