import { useCallback } from 'react'

import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { faColumns3, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'
import Title from 'antd/es/typography/Title'
import { computed, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Drawer } from 'src/components/Drawer'
import HiddenColumnsHeader, {
  HIDDEN_SECTION_HEADER_KEY,
} from 'src/components/TableViews/ManageColumnsDrawer/HiddenColumnsHeader'
import ManageColumnsDrawerItem from 'src/components/TableViews/ManageColumnsDrawer/ManageColumnsDrawerItem'
import { StyledHeader } from 'src/components/TableViews/ManageColumnsDrawer/styled-components'
import UpdateCustomColumnModal from 'src/components/TableViews/ManageColumnsDrawer/UpdateCustomColumnModal'
import { useUpdateViewMutation } from 'src/fetch/views'
import useDisclosure, { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewColumn } from 'src/models/TableViews/TableViewColumn'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

type Props = {
  modalState: UseDisclosureReturn
  tableViewManager: TableViewManager
  view: TableView
}
const ManageColumnsDrawer = ({ modalState, tableViewManager, view }: Props) => {
  const columnManager = view.columnManager
  const { mutateAsync: updateView } = useUpdateViewMutation()
  const createCustomColumnModalState = useDisclosure()

  const saveChanges = useCallback(async () => {
    await runInAction(async () => {
      await updateView({
        id: view.id,
        input: tableViewManager.getCreateViewInput(view.databaseProtocol), // Here, intentionally NOT saving the client changes to filters and sorts by using `databaseProtocol`; the invariant is that column changes should never stay unsaved
      })
    })
  }, [tableViewManager, updateView, view.databaseProtocol, view.id])

  const handleColumnVisibilityToggled = useCallback(
    async (columnGeneratedId: string) => {
      runInAction(() => {
        columnManager.toggleColumnTypeVisibility(columnGeneratedId)
      })
      await saveChanges()
    },
    [columnManager, saveChanges]
  )

  const handleColumnsReordered = useCallback(
    async (e: DragEndEvent) => {
      const draggedColumnId = e.active?.id as string
      const displacedColumnId = e.over?.id as string
      await runInAction(async () => {
        const sourceIndex = columnManager.columns.findIndex(
          (c) => c.columnTemplateGeneratedId === draggedColumnId
        )
        const destinationIndex = columnManager.columns.findIndex(
          (c) => c.columnTemplateGeneratedId === displacedColumnId
        )
        const newColumns = [...columnManager.columns]

        // Dragged column type was hidden, create a column
        if (sourceIndex < 0) {
          const columnTemplate = tableViewManager.columnTemplates.find(
            (ct) => ct.generatedId === draggedColumnId
          )
          if (!columnTemplate) {
            return
          }
          const newColumn = new TableViewColumn({
            columnGeneratedId: draggedColumnId,
            columnTemplateStore: tableViewManager.columnTemplateStore,
            displayIndex: destinationIndex,
          })
          // Column dragged to end of visible columns
          if (displacedColumnId === HIDDEN_SECTION_HEADER_KEY) {
            newColumns.push(newColumn)
          }
          // Column dragged into beginning or middle of visible columns
          else if (destinationIndex >= 0) {
            newColumns.splice(destinationIndex, 0, newColumn)
          }
        }
        // Dragged column type was visible but is becoming hidden
        else if (destinationIndex < 0) {
          if (columnManager.columns.length <= 1) {
            return // don't allow hiding all columns
          }
          newColumns.splice(sourceIndex, 1)
        }
        // Visible columns are rearranged
        else {
          const sourceColumn = newColumns.splice(sourceIndex, 1)[0]
          if (sourceColumn) {
            newColumns.splice(destinationIndex, 0, sourceColumn)
          }
        }

        columnManager.columns = newColumns
        await saveChanges()
      })
    },
    [
      columnManager,
      saveChanges,
      tableViewManager.columnTemplateStore,
      tableViewManager.columnTemplates,
    ]
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  )

  const hiddenColumnTemplates = computed(() =>
    tableViewManager.columnTemplates
      .filter((ct) => !columnManager.columnsByColumnGeneratedId.has(ct.generatedId))
      .sort((a, b) => a.labelSentenceCase.localeCompare(b.labelSentenceCase))
  ).get()

  const keys = computed(() => [
    ...columnManager.columns.map((c) => c.columnTemplateGeneratedId),
    HIDDEN_SECTION_HEADER_KEY,
    ...hiddenColumnTemplates.map((ct) => ct.generatedId),
  ]).get()

  return (
    <Drawer {...modalState} title="Manage Columns" icon={faColumns3} width="400px">
      <Flex vertical gap="12px">
        <Flex justify="space-between" align="center">
          <Title level={5} style={{ margin: 0 }}>
            Columns
          </Title>
          {view.hasCustomizableColumnTemplates && (
            <Button
              size="small"
              onClick={createCustomColumnModalState.open}
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              Create column
            </Button>
          )}
        </Flex>
        {createCustomColumnModalState.isOpen && (
          <UpdateCustomColumnModal
            modalState={createCustomColumnModalState}
            mode="create"
            onColumnVisibilityToggled={handleColumnVisibilityToggled}
            tableViewManager={tableViewManager}
            view={view}
          />
        )}
        <Flex vertical>
          <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleColumnsReordered}
          >
            <SortableContext items={keys} strategy={verticalListSortingStrategy}>
              <StyledHeader>Shown in table</StyledHeader>
              {columnManager.columns.map((column) => {
                if (!column.columnTemplate) {
                  return null
                }
                return (
                  <ManageColumnsDrawerItem
                    columnTemplate={column.columnTemplate}
                    isCustomColumn={view.customizableColumnTemplatesByType.has(
                      column.columnTemplate.columnType
                    )}
                    isVisible
                    onColumnVisibilityToggled={handleColumnVisibilityToggled}
                    tableViewManager={tableViewManager}
                    view={view}
                    key={column.columnTemplateGeneratedId}
                  />
                )
              })}
              <HiddenColumnsHeader />
              {hiddenColumnTemplates.map((ct) => (
                <ManageColumnsDrawerItem
                  columnTemplate={ct}
                  isCustomColumn={view.customizableColumnTemplatesByType.has(ct.columnType)}
                  isVisible={false}
                  onColumnVisibilityToggled={handleColumnVisibilityToggled}
                  tableViewManager={tableViewManager}
                  view={view}
                  key={ct.generatedId}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default observer(ManageColumnsDrawer)
