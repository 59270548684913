import { saveAs } from 'file-saver'
import JSZip from 'jszip'

export type DownloadSingleFileParams = {
  url: string
  downloadableName: string
}

export interface DownloadZipFileParams {
  zipFileName: string
  files: DownloadSingleFileParams[]
}

export const downloadZip = async (params: DownloadZipFileParams) => {
  const { zipFileName, files } = params
  const zip = new JSZip()

  // Fetch files and add them to the zip
  const fetchPromises = files.map(async (file) => {
    const response = await fetch(file.url)
    const blob = await response.blob()
    zip.file(file.downloadableName, blob)
  })

  await Promise.all(fetchPromises)

  // Generate the zip file
  zip
    .generateAsync({ type: 'blob' })
    .then((content) => {
      // Save the zip file
      saveAs(content, zipFileName)
    })
    .catch((error) => {
      console.error('Error generating zip file:', error)
    })
}

export const downloadFile = async (params: DownloadSingleFileParams) => {
  const response = await fetch(params.url)
  const blob = await response.blob()
  saveAs(blob, params.downloadableName)
}
