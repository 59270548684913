import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import {
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ColumnDef,
  ExpandedState,
  PaginationState,
  Row,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import { Flex, Pagination, Tooltip } from 'antd'
import { Checkbox } from 'govwell-ui'
import { useTableContext } from 'govwell-ui/components/Table/context'
import TableHeaderCell from 'govwell-ui/components/Table/TableHeaderCell'
import TableRow from 'govwell-ui/components/Table/TableRow'
import {
  TableColumnId,
  TableDropTargetDragState,
  TableRowDragState,
} from 'govwell-ui/components/Table/types'
import { useTable } from 'govwell-ui/components/Table/use-table'
import { SortingState } from 'govwell-ui/components/Table/use-table-state'
import { isTableDropTargetDragState, isTableRowDragState } from 'govwell-ui/components/Table/util'
import isNil from 'lodash.isnil'
import styled from 'styled-components'

import DragIndicator from 'src/components/DragIndicator'
import EmptyState from 'src/components/EmptyState'
import { IconOnlyButton } from 'src/components/shared/StyledComponents'
import { ColorToken } from 'src/constants/theme/types'
import useDisclosure from 'src/hooks/use-disclosure'

const StyledTable = styled.table<{
  $isLoading: boolean
  $layout: React.CSSProperties['tableLayout']
  $minWidth?: React.CSSProperties['minWidth']
}>`
  table-layout: ${({ $layout }) => $layout};
  ${({ $minWidth }) => !isNil($minWidth) && `min-width: ${$minWidth};`};
  width: 100%;
  padding: 0;
  border-spacing: 0px;
  ${({ $isLoading }) =>
    $isLoading
      ? `
    opacity: 0.5;
    pointer-events: none;
  `
      : ''}
`
const StyledTHead = styled.thead<{ $colorToken?: ColorToken }>`
  background-color: ${({ $colorToken = 'colorFillAlter', theme }) => theme[$colorToken] as string};
  padding: 0;
`
const StyledEmptyStateWrapper = styled.div<{ $hasBorderRadius: boolean; $isDropTarget: boolean }>`
  > div {
    padding: 12px 0px;
    border: solid 1px ${({ theme }) => theme.colorSplit};
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    ${({ $hasBorderRadius }) =>
      $hasBorderRadius
        ? `
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    `
        : `
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
    transition: background-color 0.25s;
    ${({ $isDropTarget, theme }) =>
      $isDropTarget &&
      `
      background-color: ${theme['blue-1']};
    `}
  }
`

type Props<TData = unknown, TSortKey extends string = string> = {
  columns: ColumnDef<TData, any>[]
  data: TData[] | null | undefined
  enableRowExpansion?: boolean
  enableRowSelection?: boolean
  enableRowReordering?: boolean
  emptyStateAction?: React.ReactNode
  emptyStateImage?: React.ReactNode
  emptyStateMessage?: React.ReactNode
  expanded?: ExpandedState
  expandButtonTooltipContent?: {
    closed: React.ReactNode
    open: React.ReactNode
  }
  getRowCanExpand?: (row: Row<TData>) => boolean
  getRowExpandedContent?: (value: TData) => React.ReactNode
  hasBorderRadius?: boolean
  isLoading?: boolean
  isSortedOnClient?: boolean
  layout?: React.CSSProperties['tableLayout']
  minWidth?: React.CSSProperties['minWidth']
  getRowId: (value: TData, index: number) => string
  onExpandedChange?: (expanded: ExpandedState) => void
  onPaginationChange?: (pagination: PaginationState) => void
  onRowDroppedIntoTable?: (sourceRow: Row<TData>) => void
  onRowReordered?: (sourceRow: Row<TData>, destinationRow: Row<TData>, closestEdge: Edge) => void
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void
  onSortingChange?: (sorting: SortingState<TSortKey>) => void
  /*
   * This prop should only be used if you are dragging rows across multiple tables. Otherwise, don't use it!
   * Add a new custom getCanReorderRow prop instead if you don't want to override the same-table invariant
   * but still need to add additional restrictions on which rows can be reordered.
   *
   * You MUST define a real invariant to replace it, do not just return true. Reordering events are global,
   * so you need to identify that the row belongs to the specific group of tables you are dragging between
   * to avoid conflicting with other draggable elements on screen.
   */
  overrideReorderingWithinSameTableInvariant?: (rowDragState: TableRowDragState<TData>) => boolean
  pagination?: PaginationState
  reorderButtonTooltipContent?: React.ReactNode
  rowSelection?: RowSelectionState
  sorting?: SortingState<TSortKey>
  totalCount?: number
}
const TableContent = <TData, TSortKey extends string = string>({
  columns: consumerDefinedColumns,
  data,
  enableRowExpansion = false,
  enableRowReordering = false,
  enableRowSelection = false,
  emptyStateAction = null,
  emptyStateImage = <EmptyState.Image />,
  emptyStateMessage = 'No results found',
  expanded,
  expandButtonTooltipContent,
  getRowCanExpand,
  getRowExpandedContent,
  getRowId,
  hasBorderRadius = true,
  isLoading,
  isSortedOnClient = false,
  layout,
  minWidth,
  onExpandedChange,
  onPaginationChange,
  onRowDroppedIntoTable,
  onRowReordered,
  onRowSelectionChange,
  onSortingChange,
  overrideReorderingWithinSameTableInvariant,
  pagination,
  reorderButtonTooltipContent,
  rowSelection,
  sorting,
  totalCount,
}: Props<TData, TSortKey>) => {
  const { size, uuid: tableUuid } = useTableContext()

  const rowSelectionColumn = useMemo(() => {
    const columnHelper = createColumnHelper<TData>()
    return columnHelper.display({
      id: TableColumnId.Select,
      meta: {
        width: layout === 'auto' ? '0px' : '16px', // In an auto layout this is taken as a suggestion, not a literal value
        dangerouslyOverrideHorizontalPadding: '4px',
      },
      header: '', // TODO: this should be configurable and allow selecting/unselecting all
      cell: ({ row }) => {
        return (
          <Flex justify="center" style={{ width: '100%' }}>
            <Checkbox value={row.getIsSelected()} onValueChange={row.getToggleSelectedHandler()} />
          </Flex>
        )
      },
    })
  }, [layout])

  const rowReorderColumn = useMemo(() => {
    const columnHelper = createColumnHelper<TData>()
    return columnHelper.display({
      id: TableColumnId.Reorder,
      header: '',
      meta: {
        width: layout === 'auto' ? '0px' : '24px', // In an auto layout this is taken as a suggestion, not a literal value
        dangerouslyOverrideHorizontalPadding: 0,
      },
      cell: () => (
        <DragIndicator tooltipContent={reorderButtonTooltipContent ?? 'Drag to reorder'} />
      ),
    })
  }, [layout, reorderButtonTooltipContent])

  const rowExpansionColumn = useMemo(() => {
    const columnHelper = createColumnHelper<TData>()
    return columnHelper.display({
      id: TableColumnId.Expand,
      meta: {
        width: layout === 'auto' ? '12px' : '24px', // In an auto layout this is taken as a suggestion, not a literal value
        dangerouslyOverrideHorizontalPadding: '2px',
      },
      cell: ({ row }) => {
        const isExpandable = row.getCanExpand()
        if (!isExpandable) {
          return null
        }

        const isExpanded = row.getIsExpanded()
        return (
          <Tooltip
            title={
              isExpanded
                ? (expandButtonTooltipContent?.open ?? 'Collapse row')
                : (expandButtonTooltipContent?.closed ?? 'Expand row')
            }
          >
            <Flex justify="center" style={{ width: '100%' }}>
              <IconOnlyButton
                icon={<FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />}
                size="small"
                onClick={row.getToggleExpandedHandler()}
              />
            </Flex>
          </Tooltip>
        )
      },
    })
  }, [expandButtonTooltipContent?.closed, expandButtonTooltipContent?.open, layout])

  const isRowReorderingEnabled = enableRowReordering && !!onRowReordered
  const isRowSelectionEnabled =
    enableRowSelection && !!rowSelection && !!onRowSelectionChange && !!data?.length
  const isRowExpansionEnabled =
    enableRowExpansion && !!expanded && !!getRowExpandedContent && !!onExpandedChange
  const columns = useMemo(
    () => [
      ...(isRowReorderingEnabled ? [rowReorderColumn] : []),
      ...(isRowSelectionEnabled ? [rowSelectionColumn] : []),
      ...(isRowExpansionEnabled ? [rowExpansionColumn] : []),
      ...consumerDefinedColumns,
    ],
    [
      consumerDefinedColumns,
      isRowExpansionEnabled,
      isRowReorderingEnabled,
      isRowSelectionEnabled,
      rowExpansionColumn,
      rowReorderColumn,
      rowSelectionColumn,
    ]
  )
  const { tableState } = useTable<TData, TSortKey>({
    columns,
    data,
    expanded,
    getRowCanExpand,
    getRowId,
    isRowExpansionEnabled,
    isRowSelectionEnabled,
    isSortedOnClient,
    onExpandedChange,
    onPaginationChange,
    onRowSelectionChange,
    onSortingChange,
    pagination,
    rowSelection,
    sorting,
  })
  const rows = tableState.getRowModel().rows

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      onPaginationChange?.({
        pageIndex: page - 1,
        pageSize,
      })
    },
    [onPaginationChange]
  )

  const tableDropTargetRef = useRef<HTMLDivElement>(null)
  const {
    isOpen: isDropTarget,
    open: prepareForDrop,
    close: stopPreparingForDrop,
  } = useDisclosure()

  useEffect(() => {
    const element = tableDropTargetRef.current
    if (!element || !onRowDroppedIntoTable) {
      return
    }
    return dropTargetForElements({
      canDrop: ({ source }) => {
        const dragData = source.data
        if (!isTableRowDragState<TData>(dragData)) {
          return false
        }
        return true
      },
      element,
      getData: (): TableDropTargetDragState => ({ isTableDropTarget: true, tableUuid }),
      getDropEffect: () => 'copy',
      onDragEnter: prepareForDrop,
      onDragLeave: stopPreparingForDrop,
      onDrop: stopPreparingForDrop,
    })
  }, [onRowDroppedIntoTable, onRowReordered, prepareForDrop, stopPreparingForDrop, tableUuid])

  useEffect(() => {
    return monitorForElements({
      onDrop: async ({ source, location }) => {
        if (!onRowDroppedIntoTable) {
          return
        }
        const destination = location.current.dropTargets[0]
        if (!destination) {
          return
        }
        const sourceData = source.data
        const destinationData = destination.data
        if (
          !isTableRowDragState<TData>(sourceData) ||
          !isTableDropTargetDragState(destinationData) ||
          destinationData.tableUuid !== tableUuid
        ) {
          return
        }
        onRowDroppedIntoTable(sourceData.row)
      },
    })
  }, [onRowDroppedIntoTable, onRowReordered, tableUuid])

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerWidth = `${tableContainerRef.current?.clientWidth ?? 0}px`

  return (
    <Flex
      vertical
      gap="12px"
      style={{
        maxWidth: '100%',
        overflowX: 'auto',
      }}
      ref={tableContainerRef}
    >
      <Flex vertical ref={tableDropTargetRef}>
        <StyledTable $isLoading={!!isLoading} $layout={layout} $minWidth={minWidth} ref={null}>
          <StyledTHead>
            {tableState.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeaderCell
                      hasBorderRadius={hasBorderRadius}
                      header={header}
                      key={header.id}
                    />
                  ))}
                </tr>
              )
            })}
          </StyledTHead>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              expanded={expanded}
              getRowExpandedContent={getRowExpandedContent}
              hasBorderRadius={hasBorderRadius}
              onRowReordered={onRowReordered}
              overrideReorderingWithinSameTableInvariant={
                overrideReorderingWithinSameTableInvariant
              }
              row={row}
              tableContainerWidth={tableContainerWidth}
            />
          ))}
        </StyledTable>
        {!rows.length && (
          <StyledEmptyStateWrapper $hasBorderRadius={hasBorderRadius} $isDropTarget={isDropTarget}>
            <EmptyState hideBorder size={size}>
              {emptyStateImage}
              <EmptyState.Message>{emptyStateMessage}</EmptyState.Message>
              {emptyStateAction}
            </EmptyState>
          </StyledEmptyStateWrapper>
        )}
      </Flex>
      <Flex justify="flex-end">
        {!!pagination && !!totalCount && totalCount > pagination.pageSize && (
          <Pagination
            current={pagination.pageIndex + 1}
            onChange={handlePaginationChange}
            pageSize={pagination.pageSize}
            style={{ position: 'sticky', bottom: 0 }}
            total={totalCount}
            showSizeChanger={false}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default TableContent
