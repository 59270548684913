/* eslint-disable @typescript-eslint/no-unused-vars */
import '@tanstack/react-table' //or vue, svelte, solid, qwik, etc.
import { Row } from '@tanstack/react-table'

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue> {
    dangerouslyOverrideHorizontalPadding?: React.CSSProperties['paddingLeft']
    textAlign?: React.CSSProperties['textAlign']
    width?: React.CSSProperties['width']
    minWidth?: React.CSSProperties['minWidth']
  }
}

export enum TableColumnId {
  Expand = 'govwell-table-expansion-column',
  Reorder = 'govwell-table-drag-handler-column',
  Select = 'govwell-table-selection-column',
}

export interface TableRowDragState<TData> extends Record<string | symbol, unknown> {
  tableUuid: string
  row: Row<TData>
}
export interface TableDropTargetDragState extends Record<string | symbol, unknown> {
  isTableDropTarget: true
  tableUuid: string
}
