import { useCallback, useState } from 'react'

import { faMemo } from '@fortawesome/pro-regular-svg-icons'
import { TextArea } from 'govwell-ui'

import Modal from 'src/components/Modal'
import { useUpdateRecordTaskInspectionAttemptMutation } from 'src/fetch/recordTaskInspectionAttempts'
import { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { RefetchFnType } from 'src/types'

type Props = {
  attemptId: number
  attemptNotes: string | null | undefined
  modalState: UseDisclosureReturn
  refetch: RefetchFnType
}
const EditInspectionNotesModal = ({ attemptId, attemptNotes, modalState, refetch }: Props) => {
  const [notes, setNotes] = useState(attemptNotes || '')

  const { mutateAsync: updateRecordTaskInspection } = useUpdateRecordTaskInspectionAttemptMutation()

  const handleSubmit = useCallback(async () => {
    await updateRecordTaskInspection({
      id: attemptId,
      input: {
        notes,
      },
    })
    await refetch()
  }, [attemptId, notes, refetch, updateRecordTaskInspection])

  return (
    <Modal
      {...modalState}
      title="Edit Inspection Notes"
      icon={faMemo}
      onOk={handleSubmit}
      okText="Save changes"
    >
      <TextArea label="Notes" value={notes} onValueChange={setNotes} />
    </Modal>
  )
}

export default React.memo(EditInspectionNotesModal)
