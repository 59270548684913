import { useCallback, useMemo } from 'react'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { Alert, Flex } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import { useScheduleMultipleRecordTaskInspectionAttemptsMutation } from 'src/fetch/recordTaskInspectionAttempts'
import { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { RefetchFnType } from 'src/types'

import { RecordTaskInspectionAttemptFragment, UserDataFragment } from '../../../../types/graphql'
import DatePicker, { DatePickerAllowedDates } from '../../form/DatePicker'
import { OwnerSelect } from '../../form/shared/OwnerSelect'
import TextArea from '../../form/TextArea'
import Modal from '../../Modal'

type FormValues = {
  date: Dayjs
  schedulingNotes: string
  inspectorUserId: string
}

type Props = {
  modalState: UseDisclosureReturn
  recordTaskInspectionAttempts: RecordTaskInspectionAttemptFragment[]
  refetch: RefetchFnType
}
const ScheduleMultipleInspectionAttemptsModal = (props: Props) => {
  const { modalState, recordTaskInspectionAttempts, refetch } = props
  const recordTaskInspectionAttemptIds = useMemo(
    () => recordTaskInspectionAttempts.map((attempt) => attempt.id),
    [recordTaskInspectionAttempts]
  )
  const isAnyInspectionAttemptUnassigned = useMemo(() => {
    return recordTaskInspectionAttempts.some((a) => !a.inspectorUser)
  }, [recordTaskInspectionAttempts])
  const { mutateAsync: scheduleInspectionAttempts } =
    useScheduleMultipleRecordTaskInspectionAttemptsMutation()
  const onOk = useCallback(
    async (formValues: FormValues) => {
      const { date, schedulingNotes, inspectorUserId } = formValues
      await scheduleInspectionAttempts({
        ids: recordTaskInspectionAttemptIds,
        input: {
          scheduledFor: dayjs(date).toISOString(),
          schedulingNotes: schedulingNotes?.trim() || undefined,
          inspectorUserId: parseInt(inspectorUserId),
        },
      })
      await refetch()
      modalState.close()
    },
    [modalState, recordTaskInspectionAttemptIds, refetch, scheduleInspectionAttempts]
  )

  return (
    <Modal
      {...modalState}
      form
      icon={faCalendar}
      title={`Schedule Inspections (${recordTaskInspectionAttemptIds.length})`}
      okText={`Schedule inspections (${recordTaskInspectionAttemptIds.length})`}
      onOk={onOk}
    >
      <DatePicker
        fieldName="date"
        label="Inspection Date"
        required
        errorMessage="You must enter a date"
        allowedDates={DatePickerAllowedDates.All}
      />
      <Flex vertical gap="12px">
        {isAnyInspectionAttemptUnassigned && (
          <Alert
            showIcon
            type="warning"
            message="One or more selected inspections has no inspector assigned. An inspector must be selected to schedule unassigned inspections."
          />
        )}
        <OwnerSelect
          label="Inspector"
          fieldName="inspectorUserId"
          caption="Optionally reassign all selected inspections to this inspector"
          placeholder="Inspector"
          filterUser={(u: UserDataFragment) => !!u.isInspector}
          {...(isAnyInspectionAttemptUnassigned
            ? {
                required: true,
                errorMessage: 'Inspector must be selected to schedule unassigned inspections',
              }
            : {})}
        />
      </Flex>
      <TextArea
        fieldName="schedulingNotes"
        label="Notes"
        placeholder="e.g. The inspector will try to be there between 2pm and 4pm."
        caption="Optionally update all selected inspections with these notes"
      />
    </Modal>
  )
}

export default React.memo(ScheduleMultipleInspectionAttemptsModal)
