import { makeAutoObservable } from 'mobx'
import { ViewColumnFragment, ViewColumnTemplateFragment } from 'types/graphql'

import { TableViewColumnTemplateStore } from 'src/models/TableViews/TableViewColumnTemplateStore'

export class TableViewColumn {
  public readonly columnTemplateGeneratedId: string
  public displayIndex: number

  private _columnTemplateStore: TableViewColumnTemplateStore

  constructor(args: {
    displayIndex: number
    columnTemplateStore: TableViewColumnTemplateStore
    columnGeneratedId: string
  }) {
    this.columnTemplateGeneratedId = args.columnGeneratedId
    this._columnTemplateStore = args.columnTemplateStore
    this.displayIndex = args.displayIndex
    makeAutoObservable(this)
  }

  /**
   * This can be null intermittently when deleting a custom column template
   */
  get columnTemplate(): ViewColumnTemplateFragment | undefined {
    return this._columnTemplateStore.columnTemplates.find(
      (ct) => ct.generatedId === this.columnTemplateGeneratedId
    )
  }

  get protocol(): ViewColumnFragment {
    return {
      columnTemplateGeneratedId: this.columnTemplateGeneratedId,
    }
  }
}
