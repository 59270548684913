import { useCallback } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { faGripDotsVertical, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Tooltip } from 'antd'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ViewColumnTemplateFragment } from 'types/graphql'

import { IconOnlyButton } from 'src/components/shared/StyledComponents'
import DeleteCustomColumnModal from 'src/components/TableViews/ManageColumnsDrawer/DeleteCustomColumnModal'
import UpdateCustomColumnModal from 'src/components/TableViews/ManageColumnsDrawer/UpdateCustomColumnModal'
import Text from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

const VisibilityButton = styled(IconOnlyButton)<{ $isVisible: boolean }>`
  border: none;
  ${({ $isVisible, theme }) => !$isVisible && `color: ${theme.colorIcon}`};
`

type Props = {
  columnTemplate: ViewColumnTemplateFragment
  isCustomColumn: boolean
  isVisible: boolean
  onColumnVisibilityToggled: (generatedId: string) => Promise<unknown>
  tableViewManager: TableViewManager
  view: TableView
}
const ManageColumnsDrawerItem = ({
  columnTemplate,
  isCustomColumn,
  isVisible,
  onColumnVisibilityToggled,
  tableViewManager,
  view,
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: columnTemplate.generatedId,
  })
  const deleteColumnModalState = useDisclosure()
  const updateColumnModalState = useDisclosure()

  const handleColumnTypeToggled = useCallback(async () => {
    await runInAction(async () => {
      await onColumnVisibilityToggled(columnTemplate.generatedId)
    })
  }, [columnTemplate.generatedId, onColumnVisibilityToggled])

  return (
    <Flex
      align="center"
      justify="space-between"
      gap="12px"
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <Flex align="center" gap="3px">
        <div {...attributes} {...listeners} style={{ cursor: 'grab', padding: '8px' }}>
          <FontAwesomeIcon icon={faGripDotsVertical} />
        </div>
        <Text>{columnTemplate.labelSentenceCase}</Text>
      </Flex>
      <Flex align="center" wrap="nowrap" gap="4px">
        {isCustomColumn && (
          <>
            <Tooltip title="Delete custom column">
              <IconOnlyButton
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={deleteColumnModalState.open}
                size="small"
              />
            </Tooltip>
            {deleteColumnModalState.isOpen && (
              <DeleteCustomColumnModal
                columnTemplate={columnTemplate}
                modalState={deleteColumnModalState}
                tableViewManager={tableViewManager}
              />
            )}
            <Tooltip title="Edit custom column">
              <IconOnlyButton
                icon={<FontAwesomeIcon icon={faPen} />}
                onClick={updateColumnModalState.open}
                size="small"
              />
            </Tooltip>
            {updateColumnModalState.isOpen && (
              <UpdateCustomColumnModal
                columnTemplate={columnTemplate}
                modalState={updateColumnModalState}
                mode="edit"
                onColumnVisibilityToggled={onColumnVisibilityToggled}
                tableViewManager={tableViewManager}
                view={view}
              />
            )}
          </>
        )}
        <Tooltip title={isVisible ? 'Hide column' : 'Show column'} placement="left">
          <VisibilityButton
            icon={<FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash} />}
            $isVisible={isVisible}
            onClick={handleColumnTypeToggled}
            size="small"
          />
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default observer(ManageColumnsDrawerItem)
