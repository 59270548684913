export const GeneratedFileFragment = gql`
  fragment GeneratedFileFragment on File {
    id
    url {
      signedUrl
    }
    type
    name
    downloadableName
  }
`
