import { FieldFragment, RecordComponentRecordTaskFragment, RecordFragment } from 'types/graphql'

type CustomTaskDisplay = {
  recordTask: RecordComponentRecordTaskFragment
  workflowStepIndex: number
}

export const getCustomTaskDisplays = (record: RecordFragment): CustomTaskDisplay[] => {
  const res: CustomTaskDisplay[] = []
  const recordTaskGroups = record.recordTaskGroups || []
  recordTaskGroups.forEach((rtg, index) => {
    const recordTasks = rtg.recordTasks || []
    recordTasks.forEach((rt) => {
      if (rt.status === 'Done' && rt.fieldGroup?.fields?.length) {
        res.push({
          recordTask: rt,
          workflowStepIndex: index + 1,
        })
      }
    })
  })
  return res
}

// Fall back to field id for migrated fields
export const getFieldNameSuffixForFormSubmittal = (field: FieldFragment) =>
  `${field.basedOnFieldId ?? field.id}`
