import { UserDataFragment } from 'types/graphql'

import Text, { TextSize } from 'src/components/Typography/Text'
import { formatUserName } from 'src/utils'

export const getOptionFromUser = (u: UserDataFragment) => {
  return {
    label: formatUserName(u),
    value: u,
  }
}

export const DuplicateUserError = () => (
  <Text size={TextSize.Large} colorToken="colorErrorBase" margin="0 0 16px 0">
    The email or phone you entered has either been invited previously or is already a GovWell user.
  </Text>
)
