import { useMemo } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  PaginationState,
  getSortedRowModel,
  OnChangeFn,
  ColumnSort,
  ColumnDef,
  getPaginationRowModel,
  ExpandedState,
  RowSelectionState,
  getExpandedRowModel,
  Row,
} from '@tanstack/react-table'
import { SortingState } from 'govwell-ui/components/Table/use-table-state'

type Params<TData, TSortKey extends string = string> = {
  columns: ColumnDef<TData, any>[]
  data: TData[] | null | undefined
  expanded?: ExpandedState
  getRowCanExpand?: (row: Row<TData>) => boolean
  getRowId: (row: TData, index: number) => string
  isRowExpansionEnabled: boolean
  isRowSelectionEnabled: boolean
  isSortedOnClient: boolean
  onExpandedChange?: (expanded: ExpandedState) => void
  onPaginationChange?: (pagination: PaginationState) => void
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void
  onSortingChange?: (sorting: SortingState<TSortKey>) => void
  pagination?: PaginationState
  rowSelection?: RowSelectionState
  sorting?: SortingState<TSortKey>
}
export const useTable = <TData, TSortKey extends string = string>({
  columns,
  data: _data,
  expanded,
  getRowCanExpand,
  getRowId,
  isRowExpansionEnabled,
  isRowSelectionEnabled,
  isSortedOnClient,
  pagination,
  onExpandedChange,
  onPaginationChange,
  onRowSelectionChange,
  onSortingChange,
  rowSelection,
  sorting,
}: Params<TData, TSortKey>) => {
  const data = useMemo(() => _data ?? [], [_data])

  const isPaginationEnabled = !!pagination
  const isSortingEnabled = !!data.length && !!onSortingChange

  const tableState = useReactTable<TData>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
    ...(isRowExpansionEnabled
      ? {
          getExpandedRowModel: getExpandedRowModel(),
          getRowCanExpand: getRowCanExpand || (() => true),
          onExpandedChange: onExpandedChange as OnChangeFn<ExpandedState> | undefined,
        }
      : {}),
    ...(isPaginationEnabled
      ? {
          getPaginationRowModel: getPaginationRowModel(),
          manualPagination: true, // Manual means we are passing paginated data to the table
        }
      : {}),
    ...(isRowSelectionEnabled
      ? {
          onRowSelectionChange: onRowSelectionChange as OnChangeFn<RowSelectionState> | undefined,
        }
      : {}),
    ...(isSortingEnabled
      ? {
          getSortedRowModel: getSortedRowModel(),
          manualSorting: !isSortedOnClient, // Manual means we are passing sorted data to the table
          onSortingChange: onSortingChange as OnChangeFn<ColumnSort[]> | undefined,
        }
      : {}),
    state: {
      ...(isRowExpansionEnabled ? { expanded } : {}),
      ...(isPaginationEnabled ? { pagination, onPaginationChange } : {}),
      ...(isRowSelectionEnabled ? { rowSelection } : {}),
      ...(isSortingEnabled
        ? {
            sorting,
          }
        : {}),
    },
  })

  return {
    tableState,
  }
}
