import { useCallback, useState } from 'react'

import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { useQueryClient } from '@tanstack/react-query'
import { Flex } from 'antd'
import { Input, Select } from 'govwell-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  FieldFragment,
  ViewColumnTemplateFragment,
  ViewCustomColumnTemplateBase,
} from 'types/graphql'

import Modal from 'src/components/Modal'
import FieldTemplateMultiSelect from 'src/components/TableViews/ManageColumnsDrawer/FieldTemplateMultiSelect'
import {
  useCreateViewCustomColumnTemplateMutation,
  useUpdateViewCustomColumnTemplateMutation,
} from 'src/fetch/views/viewColumnTemplates'
import { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'
import { QueryKey } from 'src/utils/queryClient'

type Props = {
  modalState: UseDisclosureReturn
  onColumnVisibilityToggled: (value: string) => Promise<unknown>
  tableViewManager: TableViewManager
  view: TableView
} & (
  | {
      mode: 'create'
      columnTemplate?: never
    }
  | {
      mode: 'edit'
      columnTemplate: ViewColumnTemplateFragment
    }
)
const UpdateCustomColumnModal = ({
  columnTemplate,
  modalState,
  mode,
  onColumnVisibilityToggled,
  tableViewManager,
  view,
}: Props) => {
  const [baseColumnTemplate] = useState<ViewCustomColumnTemplateBase | undefined>(
    view.customizableColumnTemplates[0]
  )
  const [columnName, setColumnName] = useState(columnTemplate?.labelTitleCase ?? '')
  const [selectedFieldTemplates, setSelectedFieldTemplates] = useState<FieldFragment[] | undefined>(
    columnTemplate?.fieldTemplates
  )

  const { mutateAsync: createColumn } = useCreateViewCustomColumnTemplateMutation()
  const { mutateAsync: updateColumn } = useUpdateViewCustomColumnTemplateMutation()
  const isDisabled = !columnName.length || !selectedFieldTemplates?.length
  const recordTypeId = tableViewManager.recordTypeId
  const queryClient = useQueryClient()

  const handleSubmit = useCallback(async () => {
    if (!recordTypeId || isDisabled || !baseColumnTemplate) {
      return
    }
    const fieldTemplateIds = selectedFieldTemplates.map((f) => f.id)
    if (mode === 'create') {
      // Create column
      const result = await runInAction(
        async () =>
          await createColumn({
            input: {
              columnType: baseColumnTemplate.columnType,
              recordTypeId,
              configuredLabel: columnName,
              fieldTemplateIds,
            },
          })
      )
      runInAction(() => {
        tableViewManager.columnTemplateStore.addColumnTemplate(
          result.createViewCustomColumnTemplate
        )
      })

      // Make it visible by default
      await onColumnVisibilityToggled(result.createViewCustomColumnTemplate.generatedId)
    } else {
      const result = await runInAction(
        async () =>
          await updateColumn({
            input: {
              generatedId: columnTemplate.generatedId,
              configuredLabel: columnName,
              fieldTemplateIds,
            },
          })
      )
      runInAction(() => {
        tableViewManager.columnTemplateStore.replaceColumnTemplate(
          result.updateViewCustomColumnTemplate
        )
      })
    }
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [QueryKey.ViewsData] }),
      queryClient.invalidateQueries({ queryKey: [QueryKey.ViewsDataCounts] }),
    ])
  }, [
    baseColumnTemplate,
    columnName,
    columnTemplate?.generatedId,
    createColumn,
    isDisabled,
    mode,
    onColumnVisibilityToggled,
    queryClient,
    recordTypeId,
    selectedFieldTemplates,
    tableViewManager.columnTemplateStore,
    updateColumn,
  ])

  return (
    <Modal
      {...modalState}
      title={mode === 'create' ? 'Create Custom Column' : 'Edit Custom Column'}
      okText={mode === 'create' ? 'Create column' : 'Save changes'}
      okDisabled={isDisabled}
      icon={faPlus}
      onOk={handleSubmit}
    >
      <Flex vertical gap="16px">
        <Input
          label="Column Name"
          value={columnName}
          onValueChange={setColumnName}
          isRequired
          placeholder="Enter a name for your column"
        />
        <Select<string>
          label="Column Type"
          caption="Create a filterable column that displays field values from records."
          isDisabled
          getOptionKey={(o) => o.value}
          options={
            baseColumnTemplate
              ? [
                  {
                    label: baseColumnTemplate.labelTitleCase,
                    value: baseColumnTemplate.labelTitleCase,
                  },
                ]
              : []
          }
          selectedOption={
            baseColumnTemplate
              ? {
                  label: baseColumnTemplate.labelTitleCase,
                  value: baseColumnTemplate.labelTitleCase,
                }
              : undefined
          }
          onSelectedOptionChange={() => {}}
        />
        {baseColumnTemplate?.columnType === 'RecordField' && (
          <FieldTemplateMultiSelect
            onChange={setSelectedFieldTemplates}
            recordTypeId={tableViewManager.recordTypeId}
            value={selectedFieldTemplates}
          />
        )}
      </Flex>
    </Modal>
  )
}

export default observer(UpdateCustomColumnModal)
