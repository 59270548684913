export const WorkflowOptionFragment = gql`
  fragment WorkflowOptionFragment on Workflow {
    id
    completedAt
    createdAt
    status
    workflowTemplate {
      id
      name
    }
  }
`
export const WorkflowFragment = gql`
  fragment WorkflowFragment on Workflow {
    id
    recordTaskGroups {
      ...WorkflowRecordTaskGroupFragment
    }
    recordTaskInspectionGroups {
      id
    }
    workflowTemplate {
      id
    }
    generatedDocuments {
      id
    }
  }
`
export const WorkflowRecordTaskGroupFragment = gql`
  fragment WorkflowRecordTaskGroupFragment on RecordTaskGroup {
    id
    status
    name
    startedAt
    completedAt
    ordinal
    type
    isLocked
    record {
      id
      applicantUser {
        ...UserDataFragment
      }
    }
    recordTaskTemplateGroup {
      id
      identifier
      changeToStatus {
        ...RecordTypeStatusFragment
      }
      defaultRenewalWorkflowTemplateId
      defaultEmailTemplate {
        id
      }
      notificationScheduledEventTemplates {
        id
      }
      workflowScheduledEventTemplates {
        id
        beginWorkflowTemplateId
        dateTemplate {
          ...DateTemplateFragment
        }
      }
      requestedRecordConfigs {
        ...RecordTaskTemplateGroupRequestedRecordFragment
      }
    }
    recordTasks {
      ...WorkflowRecordTaskFragment
    }
  }
`
export const WorkflowRecordTaskFragment = gql`
  fragment WorkflowRecordTaskFragment on RecordTask {
    id
    uuid
    name
    dueDate
    updatedAt
    startedAt
    completedAt
    status
    type
    isWaitingOnApplicant
    applicationReviewComment
    applicationReviewResult
    applicationReviewedAt
    applicationReviewCanceledAt
    applicationReviewDeclinedAt
    plansSetReview {
      id
      totalComments
      recordPlansSetId
      result {
        id
        name
        isPassing
      }
    }
    checklistItems {
      ...RecordTaskChecklistItemFragment
    }
    completedByUser {
      ...UserDataFragment
    }
    createdRecord {
      id
      uuid
    }
    fieldGroup {
      ...FieldGroupFragment
    }
    completedByUser {
      ...UserDataFragment
    }
    ownerUser {
      ...UserDataFragment
    }
    recordTaskPayment {
      totalWhenInvoiceRequestedCents
    }
    recordTaskTemplate {
      name
      addFilesToPlanReview
      taskTemplate {
        id
        identifier
        fieldGroup {
          ...FieldGroupFragment
        }
      }
    }
    requestedRecordTemplate {
      ...RecordTemplateOptionFragment
    }
  }
`
