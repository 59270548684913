import { makeAutoObservable } from 'mobx'
import { ViewColumnTemplateFragment } from 'types/graphql'

export class TableViewColumnTemplateStore {
  private _columnTemplates: ViewColumnTemplateFragment[]

  constructor(args: { columnTemplates: ViewColumnTemplateFragment[] }) {
    this._columnTemplates = args.columnTemplates
    makeAutoObservable(this)
  }

  public addColumnTemplate(columnTemplate: ViewColumnTemplateFragment) {
    this._columnTemplates.push(columnTemplate)
  }

  private _findIndexOfColumnTemplate(columnTemplate: ViewColumnTemplateFragment): number {
    return this._columnTemplates.findIndex((ct) => ct.generatedId === columnTemplate.generatedId)
  }

  public deleteColumnTemplate(columnTemplate: ViewColumnTemplateFragment) {
    const index = this._findIndexOfColumnTemplate(columnTemplate)
    if (index < 0) {
      return
    }
    this._columnTemplates.splice(index, 1)
  }

  public getColumnTemplateByGeneratedId(
    generatedId: string
  ): ViewColumnTemplateFragment | undefined {
    return this._columnTemplates.find((ct) => ct.generatedId === generatedId)
  }

  public replaceColumnTemplate(columnTemplate: ViewColumnTemplateFragment) {
    const index = this._findIndexOfColumnTemplate(columnTemplate)
    if (index < 0) {
      return
    }
    this._columnTemplates.splice(index, 1, columnTemplate)
  }

  public reset() {
    this._columnTemplates = []
  }

  public get columnTemplates(): ViewColumnTemplateFragment[] {
    return this._columnTemplates
  }
}
