import { forwardRef, useEffect, useRef } from 'react'

import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import styled, { css } from 'styled-components'

import { IconOnlyButton } from 'src/components/shared/StyledComponents'
import useDisclosure from 'src/hooks/use-disclosure'
import { useMergeRefs } from 'src/hooks/use-merge-refs'

const StyledButton = styled(IconOnlyButton)`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`

type Props = {
  tooltipContent?: React.ReactNode
}
const DragIndicator = forwardRef<HTMLButtonElement, Props>(({ tooltipContent }, propRef) => {
  const { isOpen, open, close } = useDisclosure()
  const internalRef = useRef<HTMLElement>(null)

  // This effect hides the tooltip when the mouse leaves OR the mouse clicks down
  // This fixes a visual issue where a piece of the tooltip would drag around while reordering
  useEffect(() => {
    const el = internalRef.current
    if (!el || !tooltipContent) {
      return
    }
    el.addEventListener('mouseenter', () => open())
    el.addEventListener('mousedown', close)
    el.addEventListener('mouseleave', close)
    return () => {
      el.removeEventListener('mouseenter', open)
      el.removeEventListener('mousedown', close)
      el.removeEventListener('mouseleave', close)
    }
  }, [close, open, tooltipContent])

  const ref = useMergeRefs(propRef, internalRef)
  return (
    <Tooltip title={tooltipContent} open={isOpen}>
      <StyledButton icon={<FontAwesomeIcon icon={faGripDotsVertical} />} ref={ref} size="small" />
    </Tooltip>
  )
})

export type DraggableStyleProps = {
  $isDragging: boolean
}
export const DraggableStyles = css<DraggableStyleProps>`
  position: relative;
  opacity: ${({ $isDragging }) => ($isDragging ? 0.25 : 1)};
`

export default React.memo(DragIndicator)
