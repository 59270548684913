import { Select as AntdSelect, SelectProps } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { DefaultOptionType } from 'antd/es/select'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

export type OptionType<TValue = DefaultOptionType['value']> = DefaultOptionType & {
  value?: TValue | null
}

export type OptionGroupType<TValue = DefaultOptionType['value']> = {
  disabled?: boolean
  label: React.ReactNode
  options?: OptionType<TValue>[]
  subLabel?: string
  value?: TValue
}

export interface Props<TValue extends string | number> extends FormFieldWrapperProps {
  disabled?: boolean
  limitOne?: boolean
  loading?: boolean
  mode?: SelectProps['mode']
  onChange?: SelectProps<TValue, OptionType<TValue>>['onChange']
  options: OptionType<TValue>[]
  placeholder?: React.ReactNode
  renderOption?: React.ComponentProps<
    typeof AntdSelect<TValue, OptionGroupType<TValue>>
  >['optionRender']
  size?: SizeType
}

export function SelectGroup<TValue extends string | number>(props: Props<TValue>) {
  const {
    disabled,
    loading,
    onChange,
    options,
    mode,
    placeholder,
    renderOption,
    size,
    ...formFieldWrapperProps
  } = props

  return (
    <Wrapper {...formFieldWrapperProps}>
      <AntdSelect<TValue, OptionType<TValue>>
        onChange={onChange}
        showSearch={true}
        placeholder={placeholder || formFieldWrapperProps?.label}
        disabled={disabled}
        options={options}
        optionRender={renderOption}
        mode={mode}
        loading={loading}
        filterOption={(input: string, optionPartial: OptionType<TValue> | undefined) => {
          if (!optionPartial) {
            return false
          }
          const val = optionPartial?.label || optionPartial?.children || optionPartial?.value
          if (!(typeof val === 'string')) {
            return false
          }
          return val?.toLowerCase().includes(input.toLowerCase())
        }}
        size={size}
      />
    </Wrapper>
  )
}

export default SelectGroup
