import { TableDropTargetDragState, TableRowDragState } from 'govwell-ui/components/Table/types'
import isNil from 'lodash.isnil'

export const isTableRowDragState = <TData>(data: unknown): data is TableRowDragState<TData> => {
  if (isNil(data)) {
    return false
  }
  const dragState = data as TableRowDragState<TData>
  return (
    !!dragState.row &&
    !!dragState.row.id &&
    !isNil(dragState.row.index) &&
    !!dragState.row.original &&
    !!dragState.tableUuid
  )
}

export const isTableDropTargetDragState = (data: unknown): data is TableDropTargetDragState => {
  if (isNil(data)) {
    return false
  }
  const dragState = data as TableDropTargetDragState
  return dragState.isTableDropTarget === true && !!dragState.tableUuid
}
